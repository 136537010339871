import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

import PhoneNumberInput from './PhoneNumberInput';
import { EMAIL_REGEX } from '../helpers/field_regex';

export default function EmailOrPhoneNumberInput(props) {
  const { formName } = props;
  let {
    register, control, backendError, hasError,
  } = props;
  const [isEmailUsed, setIsEmailUsed] = useState(true);
  const [email, setEmail] = useState();
  const hookForm = useForm({ mode: 'onChange' });
  register ||= hookForm.register;
  control ||= hookForm.control;
  backendError ||= false;
  hasError ||= () => false;

  const handleUseEmailCheckboxChange = (event) => {
    setIsEmailUsed(event.target.checked);
  };

  const checkPhoneNumber = (value) => {
    if (value.mob_number.match(/[^\d+]/g)) {
      setEmail(value.mob_number);
      setIsEmailUsed(true);
    }
  };

  const nameProp = `${formName}[email_or_phone_number]`;
  const inputProps = register(nameProp, { required: false, maxLength: 255, pattern: EMAIL_REGEX });

  return (
    <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left">
      { isEmailUsed ? (
        <TextField
          name={nameProp}
          label="Email"
          variant="outlined"
          size="small"
          inputProps={inputProps}
          error={backendError || hasError('email_or_phone_number')}
          helperText={hasError('email_or_phone_number') && 'Invalid email'}
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginTop: '10px' }}
        />
      ) : (
        <Controller
          control={control}
          name={nameProp}
          defaultValue=""
          render={(renderProps) => (
            <PhoneNumberInput
              formName={formName}
              phoneNumberKey="email_or_phone_number"
              label="Phone number"
              variant="outlined"
              size="small"
              autoFocus
              error={backendError || hasError('email_or_phone_number')}
              helperText={hasError('email_or_phone_number') && 'Invalid phone number'}
              replaceInvalidSymbols={false}
              onChange={(v) => {
                const { onChange } = renderProps.field;
                checkPhoneNumber(v);
                onChange(v);
              }}
            />
          )}
        />
      )}
      <FormControlLabel
        control={(
          <Checkbox
            name="use_email"
            checked={isEmailUsed}
            size="small"
            onChange={handleUseEmailCheckboxChange}
          />
        )}
        label="Use email"
      />
    </Box>
  );
}
