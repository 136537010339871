import React, { useState } from 'react';
import { parse } from 'date-fns';
import { MobileDatePicker as BaseDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';

const DatePicker = (props) => {
  const {
    date,
    dateFormat = 'dd/MM/yyyy',
    disabled = false,
    variant = 'dialog',
    showInput = false,
    applyDate,
    open,
    inputId,
    inputName,
    inputRef,
    className,
    registerProps,
    actions = [],
  } = props;
  const [isOpen, setIsOpen] = useState(open);
  const [selectedDate, setSelectedDate] = useState(date ? parse(date, dateFormat, new Date()) : null);

  const handleClose = () => setIsOpen(false);
  const handleDateChange = (value) => {
    setSelectedDate(value);
    if (applyDate) { applyDate(value); }
    if (open) handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <BaseDatePicker
        variant={variant}
        value={selectedDate}
        onClose={handleClose}
        onChange={handleDateChange}
        onAccept={handleDateChange}
        autoOk
        disabled={disabled}
        format="dd/MM/yyyy"
        {...(open && { open: isOpen })}
        {...(variant === 'dialog' && { okLabel: '' })}
        {...(!showInput && { TextFieldComponent: () => null })}
        slotProps={{
          textField: {
            InputProps: {
              ref: inputRef,
              fullWidth: true,
              className,
              name: inputName,
              id: inputId,
              ...registerProps,
            },
          },
          actionBar: {
            actions,
          },
        }}
        PopoverProps={{ disableRestoreFocus: true }}
        {...registerProps}
      />
    </LocalizationProvider>
  );
};
export default DatePicker;
