import React from 'react';
import TextField from '@mui/material/TextField';

import DynamicItem from './shared/DynamicItem';
import PhoneNumberInput from './PhoneNumberInput';

export default function DynamicInput(props) {
  const { inputClass, values } = props;

  const defaultValue = () => ({
    id: `item${Math.random()}`, email: '', full_name: '', mob_number: '', password: '',
  });
  const isValuePresent = (item) => !!(item.email || item.full_name || item.mob_number || item.password);

  return (
    <DynamicItem values={values} defaultValue={defaultValue} isValuePresent={isValuePresent}>
      <TextField
        key="full_name"
        name="invite[][full_name]"
        label="Full Name"
        size="small"
        inputProps={{ className: inputClass }}
      />
      <TextField
        key="email"
        name="invite[][email]"
        label="Email"
        size="small"
        inputProps={{ className: inputClass }}
      />
      <PhoneNumberInput
        key="mob_number"
        formName="invite[]"
        phoneNumberKey="mob_number"
        label="Phone number"
        size="small"
        inputProps={{ className: inputClass }}
        disableonchange="true"
      />
      <TextField
        key="password"
        name="invite[][password]"
        label="Password"
        size="small"
        type="password"
        inputProps={{ className: inputClass, autoComplete: 'new-password' }}
      />
    </DynamicItem>
  );
}
