import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CancelRounded from '@mui/icons-material/CancelRounded';

import BaseTable from '../common/BaseTable';

const useStyles = makeStyles(() => ({
  actionCellContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function Materials({ rows, projectId }) {
  const classes = useStyles();

  const headCells = [
    { key: 'name', label: 'Material name' },
    {
      key: 'action', label: '', customContent: true, unclickable: true,
    },
  ];

  const handleRowClick = ({ id }) => {
    $.ajax({
      url: `/projects/${projectId}/materials/${id}/edit.js`,
      type: 'GET',
    });
  };

  const customCellContentProvider = ({ id }, key) => {
    if (key === 'action') {
      return (
        <div className={classes.actionCellContent}>
          <a
            data-confirm="Are you sure"
            rel="nofollow"
            data-method="delete"
            href={`/projects/${projectId}/materials/${id}`}
          >
            <CancelRounded color="error" fontSize="small" />
          </a>
        </div>
      );
    }

    return null;
  };

  return (
    <BaseTable
      rows={rows}
      headCells={headCells}
      handleRowClick={handleRowClick}
      customCellContentProvider={customCellContentProvider}
    />
  );
}
