import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

const CheckboxResponse = (props) => {
  const {
    answerOptions, answer, error, helperText, register,
  } = props;
  const [answers, setAnswers] = useState(answerOptions.map(() => false));

  useEffect(() => {
    const initialAnswers = answerOptions
      .map((answerOption) => answer && answer.indexOf(answerOption.answer) !== -1);
    setAnswers(initialAnswers);
  }, [answerOptions, answer]);

  const registerProps = register('completion_mark[options][answer]');
  const handleChange = (event, index) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = event.target.checked;
    setAnswers(updatedAnswers);
    registerProps.onChange(event);
  };

  return (
    <FormControl variant="standard" error={error}>
      {
        answerOptions.map((option, index) => (
          <FormControlLabel
            control={(
              <Checkbox
                name="completion_mark[options][answer]"
                value={option.answer}
                checked={answers[index]}
                {...registerProps}
                onChange={(e) => handleChange(e, index)}
              />
            )}
            label={option.answer}
            key={option.answer}
          />
        ))
      }
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default CheckboxResponse;
