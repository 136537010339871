import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from './ListItem';

const useStyles = makeStyles((theme) => ({
  rootList: {
    borderTop: `1px solid ${theme.palette.black600}`,
    borderLeft: `1px solid ${theme.palette.black600}`,
    borderRight: `1px solid ${theme.palette.black600}`,
    borderRadius: 6,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ItemsList = ({
  items, component = 'nav', nested, children,
}) => {
  const classes = useStyles();

  return (
    <List
      component={component}
      className={nested ? '' : classes.rootList}
      disablePadding
    >
      {children}
      {items.map((item) => <ListItem key={item.title} item={item} className={nested ? classes.nested : ''} />)}
    </List>
  );
};

export default ItemsList;
