import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CancelRounded from '@mui/icons-material/CancelRounded';
import { openAllocatedPlantsPopup } from '../../src/helpers';
import BaseTable from '../common/BaseTable';

const useStyles = makeStyles(() => ({
  actionCellContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function Locations({ rows, projectId }) {
  const classes = useStyles();

  const headCells = [
    { key: 'name', label: 'Location' },
    { key: 'type', label: 'Type' },
    { key: 'parent_location', label: 'Within' },
    {
      key: 'allocated_plants', label: 'Access', customContent: true, customClickHandler: true,
    },
    {
      key: 'action', label: '', customContent: true, unclickable: true,
    },
  ];

  const handleRowClick = ({ id }) => {
    $.ajax({
      url: `/projects/${projectId}/locations/${id}/edit.js`,
      type: 'GET',
      success() {
        $('#location-form').data('id', id).dialog({
          modal: true,
          width: 'auto',
          resizable: false,
          title: 'Edit location',
          autoOpen: true,
          close() { $(this).dialog('destroy'); },
        });
      },
    });
  };

  const customClicksHandler = ({ id }, key) => {
    if (key === 'allocated_plants') {
      $.ajax({
        type: 'GET',
        url: `/projects/${projectId}/locations/${id}/allocated_plants_popup`,
        success(content) { openAllocatedPlantsPopup(content); },
      });
    }
  };

  const customCellContentProvider = (row, key) => {
    if (key === 'action') {
      return (
        <div className={classes.actionCellContent}>
          <a
            data-confirm="Are you sure"
            rel="nofollow"
            data-method="delete"
            href={`/projects/${projectId}/locations/${row.id}`}
          >
            <CancelRounded color="error" fontSize="small" />
          </a>
        </div>
      );
    }
    if (key === 'allocated_plants') {
      return (
        <span className="allocated-plants">
          {row.allocated_plants}
        </span>
      );
    }

    return null;
  };

  return (
    <BaseTable
      rows={rows}
      headCells={headCells}
      handleRowClick={handleRowClick}
      customClicksHandler={customClicksHandler}
      customCellContentProvider={customCellContentProvider}
    />
  );
}
