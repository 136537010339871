import {
  white100,
  black400,
  linknblue100,
} from '../../assets/stylesheets/new_styles/colors.scss';

export function sectionGraph(options) {
  var containerId = options.containerId;
  var dataURL = options.dataURL;

  var margin = { top: 70, right: 10, bottom: 50, left: 10 };
  var sectionHeight = 20;
  var sectionHeightBottomMargin = 10;
  var xLabelFlipThresholdRatio = 0.8;
  var xLabelLongest = 0;

  var data = void 0,
      lineData = void 0,
      barData = void 0;

  var container = d3.select("#" + containerId).classed("section-graph", true);

  var dimension = getContainerDimension();
  var containerWidth = dimension[0];
  var containerHeight = dimension[1];

  var width = containerWidth - margin.left - margin.right;
  var height = void 0;

  function marginBottom() {
    if (xLabelLongest >= xScale.step() * xLabelFlipThresholdRatio) {
      return sectionHeight + sectionHeightBottomMargin + xLabelLongest + 8;
    } else {
      return margin.bottom;
    }
  }

  var xScale = d3.scaleBand().range([0, width]).paddingInner(0.3).paddingOuter(0.15);

  var xLineScale = d3.scaleLinear().domain([0, 100]).range([0, width]);

  var yScale = d3.scaleLinear();

  var xAxis = d3.axisBottom().scale(xScale).tickSize(0).tickFormat(function (d) {
    return data.x_axis_names[d];
  });

  function customXAxis(g) {
    if (marginBottom() > margin.bottom) {
      g.call(xAxis).selectAll(".tick text").attr("text-anchor", "end").attr("dy", "0.1em").
        attr("transform", "rotate(-90)translate(-3)").style('fill', linknblue100);
    } else {
      g.call(xAxis).selectAll(".tick text").attr("text-anchor", "middle").attr("dy", "0.71em").attr("transform", null).
        style('fill', linknblue100);
    }
  }

  var linePath = d3.line().x(function (d) {
    return xLineScale(d.x);
  }).y(function (d) {
    return yScale(d.y);
  });

  var svg = container.append("svg").attr("width", "100%").attr("height", "100%");

  var backgroundRect = svg.append("rect").attr("class", "background-rect").attr("x", 0).attr("y", 0).attr("width", "100%").attr("height", "100%");

  var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var tooltip = container.append("div").attr("class", "graph-tooltip").style("opacity", 0);

  data = dataURL.graph;
  processData();
  renderChart();

  function processData() {
    lineData = data.series.filter(function (s) {
      return s.type === "Line";
    });
    lineData.forEach(function (d) {
      if (d.labels) {
        d.labelsData = d.labels.map(function (label, i) {
          return {
            index: i,
            x: d.values[i].x,
            y: d.values[i].y,
            label: label
          };
        });
      }
    });

    barData = data.series.filter(function (s) {
      return s.type === "Bar";
    });

    var accValues = d3.range(data.x_axis_names.length).map(function (d) {
      return 0;
    });
    barData.forEach(function (d) {
      d.accValues = accValues.map(function (accValue, i) {
        let value = d.values[i];
        if (isObject(value)) value = value.value;
        var newAccValue = accValue + (value ? value : 0);
        newAccValue = Math.round(newAccValue * 10) / 10;
        accValues[i] = newAccValue;
        return [accValue, newAccValue];
      });
    });

    if (barData[barData.length - 1].labels.length) {
      var labels = barData[barData.length - 1].labels;
      barData.labelsData = accValues.map(function (accValue, i) {
        return {
          index: i,
          accValue: accValue,
          label: labels[i]
        };
      });
    }

    // Update scales
    xScale.domain(d3.range(data.x_axis_names.length));

    var gXLabelTemp = g.append("g");
    gXLabelTemp.call(xAxis).selectAll(".tick text").each(function () {
      var xLabelWidth = this.getBoundingClientRect().width;
      xLabelLongest = Math.max(xLabelLongest, xLabelWidth);
    });
    gXLabelTemp.remove();

    height = containerHeight - margin.top - marginBottom();

    var lineDataYMax = d3.max(lineData, function (d) {
      return d3.max(d.values, function (e) {
        return e.y;
      });
    });
    var barDataYMax = d3.max(barData[barData.length - 1].accValues, function (d) {
      return d[1];
    });
    var yMax = d3.max([lineDataYMax, barDataYMax]);
    yScale.domain([0, yMax]).range([height, 0]);
  }

  function renderChart() {
    // Title
    g.append("text").attr("class", "graph-heading").attr("x", "50%").attr("y", -45).attr("text-anchor", "middle").
      style('fill', linknblue100).text(data.graph_heading);

    g.append("text").attr("class", "graph-subtext").attr("x", "50%").attr("y", -25).attr("text-anchor", "middle").
      style('fill', linknblue100).text(data.graph_subtext);

    // Axis
    g.append("g").attr("class", "graph-x-axis").attr("transform", "translate(0," + height + ")").call(customXAxis);

    // Section
    var section = g.append("g").attr("class", "graph-sections").attr("transform", "translate(0," + (height + marginBottom() - sectionHeight - sectionHeightBottomMargin) + ")").selectAll(".graph-section").data(data.sections).enter().append("g").attr("class", "graph-section");

    section.append("rect").attr("x", function (d) {
      return d.start_index * xScale.step();
    }).attr("y", 0).attr("width", function (d) {
      return (d.stop_index - d.start_index + 1) * xScale.step();
    }).attr("height", sectionHeight).attr("fill", function (d) {
      return d.colour;
    });

    section.append("text").attr("x", function (d) {
      return (d.stop_index + d.start_index + 1) / 2 * xScale.step();
    }).attr("y", sectionHeight / 2).attr("dy", "0.35em").attr("text-anchor", "middle").style('fill', white100).text(function (d) {
      return d.label;
    });

    // Bar
    var gBar = g.append("g").attr("class", "graph-bars");
    var bar = gBar.selectAll(".graph-bar-series").data(barData).enter().append("g").attr("class", "graph-bar-series").selectAll(".graph-bar").data(function (d) {
      return d.accValues.map(function (accValue, i) {
        var value = d.values[i];
        let printValue = '';
        let showTotalValue = false;
        if (isObject(value)) {
          printValue = value.description;
          showTotalValue = value.show_total_value;
          value = value.value;
        } else if (d.print_values) {
          printValue = "" + d.prefix + value + d.postfix;
        }
        return {
          index: i,
          accValue: accValue,
          value: value,
          printValue: printValue,
          showTotalValue: showTotalValue,
          color: d.colour
        };
      }).filter(function (e) {
        return e.value;
      });
    }).enter().append("g").attr("class", "graph-bar").on("mouseover", showTooltip).on("mousemove", moveTooltip).on("mouseout", hideTooltip);

    bar.append("rect").attr("class", "graph-bar--rect").attr("x", function (d) {
      return xScale(d.index);
    }).attr("y", function (d) {
      return yScale(d.accValue[1]);
    }).attr("width", xScale.bandwidth()).attr("height", function (d) {
      return yScale(d.accValue[0]) - yScale(d.accValue[1]);
    }).attr("fill", function (d) {
      return d.color;
    });

    bar.append("text").attr("class", "graph-bar--text").attr("x", function (d) {
      return xScale(d.index) + xScale.bandwidth() / 2;
    }).attr("y", function (d) {
      return yScale(d.accValue[1]);
    }).attr("dy", "1.2em").attr("text-anchor", "middle").text(function (d) {
      return d.printValue;
    }).style("display", isBarTextDisplayed);

    bar.append("text").attr("class", "graph-bar--text").attr("x", function (d) {
      return xScale(d.index) + xScale.bandwidth() / 2;
    }).attr("y", function (d) {
      return yScale(d.accValue[1]) - 18;
    }).attr("dy", "1.2em").attr("text-anchor", "middle").text(function (d) {
      return d.accValue[1];
    }).style('fill', linknblue100).style("display", topBarTextDisplayStyle);

    if (barData.labelsData) {
      gBar.selectAll(".graph-bar--label").data(barData.labelsData).enter().append("text").attr("class", "graph-bar--label").attr("x", function (d) {
        return xScale(d.index) + xScale.bandwidth() / 2;
      }).attr("y", function (d) {
        return yScale(d.accValue);
      }).attr("dy", "-0.45em").attr("text-anchor", "middle").text(function (d) {
        return d.label;
      });
    }

    // Line
    var line = g.append("g").attr("class", "graph-lines").selectAll(".graph-line").data(lineData).enter().append("g").attr("class", "graph-line");

    line.append("path").attr("class", "graph-line--line").attr("stroke", function (d) {
      return d.colour;
    }).attr("d", function (d) {
      return linePath(d.values);
    });

    line.filter(function (d) {
      return d.labelsData;
    }).selectAll("graph-line--label").data(function (d) {
      return d.labelsData;
    }).enter().append("text").attr("class", "graph-line--label").attr("x", function (d) {
      return xLineScale(d.x);
    }).attr("y", function (d) {
      return yScale(d.y);
    }).attr("dy", "-0.5em").text(function (d) {
      return d.label;
    }).style('fill', linknblue100);
  }

  function isObject(value) {
    return typeof value === 'object' && value !== null
  }

  function showTooltip(d) {
    tooltip.html("\n      <div>" + d.printValue + "</div>\n    ");
    tooltip.style("background-color", black400).transition().style("opacity", 1);
  }

  function moveTooltip() {
    var xOffset = 10;
    var yOffset = 10;
    var tooltipBCR = tooltip.node().getBoundingClientRect();
    var left = d3.event.clientX + xOffset;
    var top = d3.event.clientY + yOffset;
    if (left + tooltipBCR.width >= window.innerWidth) {
      left = d3.event.clientX - xOffset - tooltipBCR.width;
    }
    if (top + tooltipBCR.height >= window.innerHeight) {
      top = window.innerHeight - tooltipBCR.height;
    }
    tooltip.style("left", left + "px").style("top", top + "px");
  }

  function hideTooltip() {
    tooltip.transition().style("opacity", 0);
  }

  function getContainerDimension() {
    var containerBCR = container.node().getBoundingClientRect();
    return [containerBCR.width, containerBCR.height];
  }

  function linePercentToPixels(percent) {
    percent / 100 * width;
  }

  function isBarTextDisplayed(d) {
    d3.select(this).style("display", "block");
    var textBCR = this.getBoundingClientRect();
    var rectBCR = d3.select(this.parentNode).select(".graph-bar--rect").node().getBoundingClientRect();
    if (textBCR.left >= rectBCR.left && textBCR.right <= rectBCR.right && textBCR.bottom <= rectBCR.bottom) {
      return "block";
    } else {
      return "none";
    }
  }

  function topBarTextDisplayStyle(d) {
    return d.showTotalValue ? "block" : "none";
  }

  function resizeChart() {
    var dimension = getContainerDimension();

    containerWidth = dimension[0];
    containerHeight = dimension[1];

    width = containerWidth - margin.left - margin.right;
    height = containerHeight - margin.top - marginBottom();

    xScale.range([0, width]);

    xLineScale.range([0, width]);

    yScale.range([height, 0]);

    // Axis
    g.select(".graph-x-axis").attr("transform", "translate(0," + height + ")").call(customXAxis);

    // Section

    var section = g.select(".graph-sections").attr("transform", "translate(0," + (height + marginBottom() - sectionHeight - sectionHeightBottomMargin) + ")").selectAll(".graph-section");

    section.select("rect").attr("x", function (d) {
      return d.start_index * xScale.step();
    }).attr("width", function (d) {
      return (d.stop_index - d.start_index + 1) * xScale.step();
    });

    section.select("text").attr("x", function (d) {
      return (d.stop_index + d.start_index + 1) / 2 * xScale.step();
    });

    // Bar
    var bar = g.selectAll(".graph-bar");

    bar.select(".graph-bar--rect").attr("x", function (d) {
      return xScale(d.index);
    }).attr("y", function (d) {
      return yScale(d.accValue[1]);
    }).attr("width", xScale.bandwidth()).attr("height", function (d) {
      return yScale(d.accValue[0]) - yScale(d.accValue[1]);
    });

    bar.select(".graph-bar--text").attr("x", function (d) {
      return xScale(d.index) + xScale.bandwidth() / 2;
    }).attr("y", function (d) {
      return yScale(d.accValue[1]);
    }).style("display", isBarTextDisplayed);

    if (barData.labelsData) {
      g.selectAll(".graph-bar--label").attr("x", function (d) {
        return xScale(d.index) + xScale.bandwidth() / 2;
      }).attr("y", function (d) {
        return yScale(d.accValue);
      });
    }

    // Line
    var line = g.selectAll(".graph-line");

    line.select(".graph-line--line").attr("d", function (d) {
      return linePath(d.values);
    });

    line.selectAll(".graph-line--label").attr("x", function (d) {
      return xLineScale(d.x);
    }).attr("y", function (d) {
      return yScale(d.y);
    });
  }

  var optimizedResize = function () {
    var callbacks = [],
        running = false;

    // fired on resize event
    function resize() {
      if (!running) {
        running = true;

        if (window.requestAnimationFrame) {
          window.requestAnimationFrame(runCallbacks);
        } else {
          setTimeout(runCallbacks, 66);
        }
      }
    }

    // run the actual callbacks
    function runCallbacks() {
      callbacks.forEach(function (callback) {
        callback();
      });

      running = false;
    }

    // adds callback to loop
    function addCallback(callback) {
      if (callback) {
        callbacks.push(callback);
      }
    }

    return {
      // public method to add additional callback
      add: function add(callback) {
        if (!callbacks.length) {
          window.addEventListener("resize", resize);
        }
        addCallback(callback);
      }
    };
  }();

  // start process
  optimizedResize.add(resizeChart);
}
window.sectionGraph = sectionGraph;
