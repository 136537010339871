import { format } from "date-fns";
import html2pdf from "html2pdf.js";

import { hideSpinner, showSpinner } from "./helpers";

export function generateCranePlanPdf() {
  showSpinner();
  var element = document.getElementById('plant_planner');
  var date = sessionStorage.getItem('selectedDate');
  var projectName = $('.plan_section').data('projectName');
  var reportTitle = `${projectName} Plant for ${date}`;
  var options = {
    margin: [22, 10, 10, 10],
    filename: reportTitle,
    html2canvas: {
      ignoreElements: (element) => hideElementForPdf(element)
    },
    jsPDF: {
      orientation: 'portrait',
      format: 'a3'
    }
  };
  var planContainer = $('.fc-time-grid-container');
  var planHeight = planContainer.height();
  planContainer.height('auto');
  html2pdf().set(options).from(element).
    toPdf().get('pdf').then((pdf) => addHeaderToPdf(pdf, reportTitle)).
    save().then(() => {
      planContainer.height(planHeight);
      hideSpinner();
    });
};

function addHeaderToPdf(pdf, title) {
  pdf.setFont('Manrope');
  pdf.setFontSize(24);
  pdf.text(title, 11, 14);
  pdf.setFontSize(14);
  pdf.text(`Generated at ${format(Date.now(), 'Y-MM-dd HH:mm:ss')}`, 11, 20);
}

function hideElementForPdf(element) {
  return ['event-buttons', 'plus'].some((className) => element.classList.contains(className));
}
