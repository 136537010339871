import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function DynamicItem(props) {
  const {
    children,
    values,
    defaultValue,
    isValuePresent,
  } = props;
  const setDefaultItems = () => [...values, defaultValue()];
  const [items, setItems] = React.useState(setDefaultItems());

  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const onChange = (event, index, key) => {
    const item = items[index];
    item[key] = event.target.value;
    let itemsCopy = [...items];
    itemsCopy[index] = item;
    if (itemsCopy.length === index + 1) itemsCopy = [...itemsCopy, defaultValue()];
    setItems(itemsCopy);
  };

  const adjustedPropsFor = (child, item, index) => {
    const properties = { value: item[child.key] || '' };
    if (child.props.disableonchange) return properties;

    properties.onChange = (e) => onChange(e, index, child.key);
    return properties;
  };

  return (
    <>
      {items.map((item, index) => (
        <Box display="flex" key={item.id}>
          <Card align="center" variant="outlined">
            <CardContent>
              {React.Children.map(children, (child) => React.cloneElement(child, adjustedPropsFor(child, item, index)))}
            </CardContent>
          </Card>
          {isValuePresent(item) && (
            <Tooltip title="Delete">
              <Box component="span" mt={1} ml={1}>
                <Fab
                  size="small"
                  onClick={() => removeItem(index)}
                >
                  <CancelIcon />
                </Fab>
              </Box>
            </Tooltip>
          )}
        </Box>
      ))}
    </>
  );
}
