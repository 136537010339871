import React from 'react';
import { MobileTimePicker as BaseTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './styles.css';

const TimePicker = React.forwardRef((props, ref) => {
  const {
    ampm = true,
    format = 'h:mm a',
    disabled = false,
    date,
    inputId,
    inputName,
    handleTimeChangeCallback,
    error,
  } = props;

  const handleAccept = (value) => {
    if (handleTimeChangeCallback) { handleTimeChangeCallback(value); }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BaseTimePicker
          variant="filled"
          ampm={ampm}
          format={format}
          value={date && new Date(date)}
          onAccept={handleAccept}
          helperText={error}
          error={!!error}
          fullWidth
          inputRef={ref}
          PopoverProps={{ disableRestoreFocus: true }}
          disabled={disabled}
          slotProps={{
            textField: {
              InputProps: {
                name: inputName, id: inputId,
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
});

TimePicker.displayName = 'TimePicker';
export default TimePicker;
