import React, { useState } from 'react';
import { addMinutes, intervalToDuration } from 'date-fns';
import Box from '@mui/material/Box';
import TimePicker from '../common/TimePicker';
import DurationInput from '../common/DurationInput';

const ActivityTimePeriodPicker = (props) => {
  const {
    startTime,
    endTime,
    initialDuration,
    resourceName,
    disabled,
    onlyDuration = false,
  } = props;

  const [selectedStartTime, setSelectedStartTime] = useState(startTime ? new Date(startTime) : null);
  const [selectedEndTime, setSelectedEndTime] = useState(endTime ? new Date(endTime) : null);

  const [duration, setDuration] = useState(initialDuration || null);

  const [isValidPeriod, setIsValidPeriod] = useState(true);

  const validatePeriod = (startT, endT) => setIsValidPeriod(endT > startT);

  const updateEndTime = (selectedTime, newDuration) => {
    if (newDuration) {
      const startTimeToUse = selectedTime || new Date(0, 0, 0, 0, 0);
      const minutesDuration = newDuration.hours * 60 + newDuration.minutes;
      const newEndTime = addMinutes(startTimeToUse, minutesDuration);

      if (!selectedTime) {
        setSelectedStartTime(startTimeToUse);
      }

      setSelectedEndTime(newEndTime);
    }
  };

  const handleStartTimeChange = (value) => {
    setSelectedStartTime(value);
    updateEndTime(value, duration);
    if (selectedEndTime) {
      validatePeriod(value, selectedEndTime);
    } else {
      setSelectedEndTime(addMinutes(value, 30));
    }
  };

  const handleEndTimeChange = (value) => {
    setSelectedEndTime(value);
    setDuration(intervalToDuration({ start: selectedStartTime, end: value }));
    if (selectedStartTime) {
      validatePeriod(selectedStartTime, value);
    } else {
      setSelectedStartTime(addMinutes(value, -30));
    }
  };

  const handleDurationChange = (value) => {
    const [hours, minutes] = value.split(':').map((v) => parseInt(v, 10));
    const totalDuration = new Date(0, 0, 0, hours, minutes);
    const newDuration = {
      hours: totalDuration.getDay() * 24 + totalDuration.getHours(),
      minutes: totalDuration.getMinutes(),
    };
    setDuration(newDuration);
    updateEndTime(selectedStartTime, newDuration);
  };

  const buildInputId = (name) => (`${resourceName}_${name}`);
  const buildInputName = (name) => (`${resourceName}[${name}]`);

  return (
    <>
      <label htmlFor={buildInputId('start_time')}>Start time</label>
      {onlyDuration
        ? (
          <>
            <br />
            <b>Immediately</b>
            <br />
          </>
        )
        : (
          <>
            <Box>
              <TimePicker
                date={selectedStartTime}
                inputName={buildInputName('start_time')}
                inputId={buildInputId('start_time')}
                handleTimeChangeCallback={handleStartTimeChange}
                disabled={disabled}
                error={!isValidPeriod && 'Must be earlier than end time'}
                style={{ marginBottom: '10px' }}
                ref={React.createRef()}
              />
            </Box>
            <Box>
              <label htmlFor={buildInputId('end_time')}>End time</label>
            </Box>
            <Box>
              <TimePicker
                date={selectedEndTime}
                inputName={buildInputName('end_time')}
                inputId={buildInputId('end_time')}
                handleTimeChangeCallback={handleEndTimeChange}
                disabled={disabled}
                error={!isValidPeriod && 'Must be later than start time'}
                ref={React.createRef()}
              />
            </Box>
          </>
        )}
      <label htmlFor={buildInputId('duration')}>{onlyDuration ? 'Set duration' : 'Or set duration:'}</label>
      <DurationInput
        value={duration}
        inputName={buildInputName('duration')}
        inputId={buildInputId('duration')}
        handleChange={handleDurationChange}
        disabled={disabled}
        ref={React.createRef()}
      />
    </>
  );
};
export default ActivityTimePeriodPicker;
