import { initializeWeekNavigationDatepickers, showSpinner } from '../helpers';
import { showFlashMessage } from '../flash';

window.drawPlanner = function (scroll) {
  if (isEmployeesPlanner() === false) { return; }
  if (scroll === undefined) { scroll = true; }
  var mobileView = $('#planner-mobile').length > 0;
  var plannerMode = $('.tasks_employees .slider');
  var employeesMode = plannerMode.hasClass('employees_mode');
  var options = optionsForDrawPlanner(employeesMode);
  if (mobileView) {
    drawMobilePlanner(options, scroll);
  } else {
    drawDesktopPlanner(options);
  }
}

function optionsForDrawPlanner(employeeMode) {
  var options = {};
  if (employeeMode) {
    options = {
      mode: 'employees',
      url: '/planned_employee_days.js',
      selectorForContent: '#employee_planner_view'
    };
  } else {
    options = {
      mode: 'tasks',
      url: '/planned_employee_days/task_planner.js',
      selectorForContent: '#tasks_planner_view'
    };
  }
  return options;
}

function isEmployeesPlanner() {
  return ($('#planner-mobile').length > 0) || ($('#employee_planner_view').length > 0);
}

function setFlexBasisForDays() {
  var weekWidth = $('.week').width();
  $('.day').each(function () {
    var daysSpan = $(this).find('[data-span]').data('span');
    var calculatedWidth = weekWidth * daysSpan / 7;
    var flexValue = '0 0 ' + calculatedWidth + 'px';
    $(this).css('flex', flexValue);
  });
}

function drawMobilePlanner(options, scroll) {
  var daysCount = $('.week_day_mobile').length;
  var firstDate = $('.prev_week_mobile').data('date');
  var dateForScroll = $('#planner-mobile').data('dateForScroll');
  var queryParams = {
    mode: options.mode,
    date: firstDate,
    days_count: daysCount,
    date_for_scroll: dateForScroll,
    scroll: scroll
  };
  $.ajax({
    data: queryParams,
    url: options.url,
    beforeSend: function () { showSpinner(); },
    complete: function () { $('.spinner').hide(); }
  });
  var url = 'mode=employees&date=' + firstDate + '&days_count=' +
    daysCount + '&date_for_scroll=' + dateForScroll + '&scroll=' + scroll;
  updateUrl(url);
}

function drawDesktopPlanner(options) {
  var date = getEmployeePlannerDate();
  $.ajax({
    url: options.url,
    dataType: 'json',
    data: { date: date, mode: options.mode },
    beforeSend: function () { showSpinner(); },
    success: function (data) {
      var content = employeePlannerContent(data, options.mode);
      $(options.selectorForContent).html(content);
      drawPlannerSuccessCallback(date, options.mode);
    },
    complete: function () { $('.spinner').hide(); },
    error: function (data) {
      var message = data.status === 302 ? 'Redirecting...' : 'Something went wrong.';
      showFlashMessage(message);
    }
  });
}

function employeePlannerContent(jsonData, mode) {
  var result = weekDaysTemplate(jsonData.week_days);
  if (mode === 'employees') {
    result += unallocatedEmployeesSectionTemplate(jsonData.unallocated);
    result += employeeProjectsTemplate(jsonData.projects);
    result += addToProjectButton(jsonData);
  } else {
    result += taskProjectsTemplate(jsonData.projects);
  }
  return result;
}

function drawPlannerSuccessCallback(date, mode) {
  if (mode === 'employees') {
    switchEmployeePlannerViews('#tasks_planner_view', '#employee_planner_view');
    setScrollOnUnallocatedSection();
    setOpacityOnUnsupervised();
  } else {
    switchEmployeePlannerViews('#employee_planner_view', '#tasks_planner_view');
  }
  updateUrl('date=' + date + '&mode=' + mode);
  setFlexBasisForDays();
  setScrollOnSupervisorSection(10);
  initializeWeekNavigationDatepickers();
}

function switchEmployeePlannerViews(sourceView, targetView) {
  $(sourceView).html('');
  $(sourceView).hide();
  $(targetView).show();
}

export function getEmployeePlannerDate() {
  return $('#start_day').val();
}

function weekDaysTemplate(days) {
  var html = days.map(function (day) {
    return '<div data-date="' + day.str_date + '"class="week-day">' + day.display_date + '</div>';
  }).join('');
  return wrapInRow(html);
}

function unallocatedEmployeesSectionTemplate(employees) {
  if (employees.length === 0) { return ''; }
  var header = topLevelHeader('Unallocated employees');
  header = wrapInRow(header);
  var employeesHtml = elementsTemplate(employees, wrapUnallocatedEmployeePeriod, 'unallocated');
  return header + wrapUnallocatedSection(employeesHtml);
}

function employeeProjectsTemplate(projects) {
  return projects.map(function (project) {
    var header = '';
    if (projects.length > 1) {
      header = topLevelHeader(project.name);
      header = wrapInRow(header);
    }
    var unsupervisedSubHeader = '';
    if (project.unsupervised.length > 0) { unsupervisedSubHeader = subHeader('Employees without supervisor'); }
    var unsupervisedHtml = elementsTemplate(project.unsupervised, wrapAllocatedEmployeePeriod, 'allocated', 'task_hover', null, project.id);
    var supervisorsHtml = employeeSupervisorsTemplate(project);
    return header + unsupervisedSubHeader + wrapUnsupervised(unsupervisedHtml) + supervisorsHtml;
  }).join('');
}

function taskProjectsTemplate(projects) {
  return projects.map(function (project) {
    var header = '';
    if (projects.length > 1) {
      header = topLevelHeader(project.name);
      header = wrapInRow(header);
    }
    var supervisorsHtml = taskSupervisorsTemplate(project.supervisors, project);
    return header + supervisorsHtml;
  }).join('');
}

function setScrollOnUnallocatedSection() {
  setScrollOnPlannerSection($('#unallocated_section'), '.week', 10);
}

function setScrollOnSupervisorSection(number) {
  $('.supervisor_section').map(function (el, _) {
    setScrollOnPlannerSection($(this), '.week', number);
  });
}

export function updateUrl(query) {
  var url = window.location.href;
  var urlParts = url.split('?');
  if (urlParts.length > 0) {
    var baseUrl = urlParts[0];
    var updatedUri = baseUrl + '?' + query;
    window.history.replaceState({}, document.title, updatedUri);
  }
}

function wrapInRow(str, supervisorId, projectId, employeeId) {
  return '<div data-employee-id="' + employeeId + '" data-project-id="' + projectId + '" data-supervisor-id="' + supervisorId + '"class="week">' + str + '</div>';
}

function topLevelHeader(str) {
  return '<div class="blue_label" data-span="7">' + str + '</div>';
}

function elementsTemplate(elements, wrapCallback, classStyle, mode, supervisorId, projectId) {
  return elements.map(function (el) {
    classStyle = el.nested_supervisor ? 'supervisor_label allocated' : 'allocated';
    var html = genericElementTemplate(el, wrapCallback, classStyle, mode);
    var template = wrapInRow(html, supervisorId, projectId, el.id);
    if(el.nested_supervisor) {
      template += '<div class="nested_employees">';
      template += elementsTemplate(el.nested_employees, wrapCallback, 'allocated', mode, el.id, projectId);
      template += '</div>';
    }
    return template;
  }).join('');
}

function wrapUnallocatedSection(str) {
  return '<div id="unallocated_section">' + str + '</div>';
}

function subHeader(name, id) {
  return '<div class="supervisor_label" data-span="7" data-id=' + id + '>' + name + '</div>';
}

function employeeSupervisorsTemplate(project) {
  return project.supervisors.map(function (supervisor) {
    var header = subHeader(supervisor.full_name, supervisor.id, project.id);
    header = wrapInCell(header, 0);
    var employeesHtml = elementsTemplate(supervisor.employees, wrapAllocatedEmployeePeriod, 'allocated', 'task_hover', supervisor.id, project.id, supervisor.grade_periods);
    return wrapInRow(header) + wrapSupervisorSection(employeesHtml);
  }).join('');
}

function wrapUnsupervised(str) {
  return '<div id="unsupervised_section">' + str + '</div>';
}

function setScrollOnPlannerSection(elem, subEl, elCount) {
  if (elem.children(subEl).length <= elCount) { return; }
  var elements = elem.children(subEl).slice(0, 10);
  var height = 0;
  elem.children(subEl + ':lt(' + elCount + ')').each(function (_, el) { height += $(el).height(); });
  elem.css({'overflow-y': 'scroll', 'height': height + 'px'});
}

function genericElementTemplate(element, elementWrapCallback, classStyle, mode, supervisorId) {
  var fullName = element.full_name;
  var totalBeforeDays = 0;
  return element.periods.map(function (period, i) {
    var daysBefore = parseInt(period.starts_on, 10);
    var periodHtml = elementWrapCallback(element, period, classStyle, mode, supervisorId);
    if (i !== 0) {
      daysBefore = daysBefore - totalBeforeDays;
    }
    totalBeforeDays += daysBefore + period.duration;
    periodHtml = wrapInCell(periodHtml, i);
    return emptyDayTemplate(daysBefore) + periodHtml;
  }).join('');
}

function wrapSupervisorSection(str) {
  return '<div class="supervisor_section">' + str + '</div>';
}

function emptyDayTemplate(number) {
  if (number <= 0) {  return ''; }
  var str = '<div class="day"><div data-span="1" style="background-color:transparent"></div></div>';
  return Array(number + 1).join(str);
}

function taskSupervisorsTemplate(supervisors, project) {
  return supervisors.map(function (supervisor) {
    var header = subHeader(supervisor.full_name, supervisor.id, project.id);
    header = wrapInCell(header, 0);
    var employeesHtml = elementsTemplate(supervisor.tasks, wrapAllocatedEmployeePeriod, 'allocated', 'employee_hover', supervisor.id, project.id);
    var addButton = addTaskButtonTemplate(supervisor, project);
    return wrapInRow(header) + wrapSupervisorSection(employeesHtml) + addButton;
  }).join('');
}

function addTaskButtonTemplate(supervisor, project) {
  var str = '<button class="nav-button add_task" data-project-id=' + project.id + ' data-supervisor-id=' + supervisor.id + '>+ Add</button>';
  return wrapInRow(wrapInCell(str));
}

function addToProjectButton(jsonData) {
  if (jsonData.employee_only_view === false) { return ''; }
  var button = '<button class="nav-button add_to_project_button" data-employee-id=' + jsonData.employee_id + '>+ Add project</button>';
  return wrapInRow(wrapInCell(button))
}

function manageButtonTemplate(project, supervisor) {
  var str = '<button data-project-id=' + project.id + ' data-supervisor-id=' + supervisor.id + ' class="nav-button manage-employees-button">Manage</button>';
  return wrapInRow(wrapInCell(str));
}

function setOpacityOnUnsupervised() {
  $('#unsupervised_section .allocated').each(function (_, el) {
    //$(el).css({'opacity': '0.5'});
  });
}

function wrapAllocatedEmployeePeriod(employee, period, classStyle, mode, supervisorId) {
  var tasks = period.tasks;
  var firstTask = tasks.shift();
  var firstCell = wrapElementName(employee.full_name) + taskTemplate(firstTask, mode, employee.competency_id, employee.task_id, employee.task_class);
  firstCell = wrapInnerCell(firstCell);
  var taskHtml = tasksTemplate(tasks, mode);
  var html = wrapInnerRow(period.duration, firstCell + taskHtml);
  return wrapAllocatedEmployee(period.duration, html, period, classStyle);
}

function wrapElementName(employeeName) {
  return '<span class="element_name">' + employeeName + '</span>';
}

function tasksTemplate(tasks, mode) {
  return tasks.map(function (task) {
    var taskHtml = taskTemplate(task, mode);
    return wrapInnerCell(taskHtml);
  }).join('');
}

function wrapInCell(str, i) {
  return '<div class="day" style="">' + str + '</div>';
}

function wrapUnallocatedEmployeePeriod(employee, period) {
  return '<div class="unallocated" data-employee-id="' + employee.id + '" data-span="' + period.duration + '">' + employee.full_name + '</div>';
}

function taskTemplate(task, mode, competencyId, taskId, taskClass) {
  var dayStatus = task.day_status;
  var actualEdId = task.actual_employee_day_id;
  var countColor = (task.total_count === task.ready_count) ? '' : 'overlap_task';
  if (task.state === 'future') {
    var futureTaskTextColor = task.total_count > task.ready_count ? 'overlap_task' : '';
    futureTaskTextColor = task.total_count === 0 ? 'overlap_task' : '';
  }
  var overlapping = futureTaskTextColor !== undefined ? futureTaskTextColor : countColor;
  var taskHtml = '';
  var stopwatchHtml = '';
  var manageActualDaySymbolHtml = '';
  if (!task.future) {
    var trackedTime = task.tracked_time;
    var stopwatchColor = trackedTime ? 'completed' : 'incompleted';
    if(task.grade === 'Grade 3') {
      if (dayStatus === 'planned') {
        stopwatchHtml = '<div class=' + stopwatchColor + '><i class="fa-stopwatch ' + stopwatchColor + ' ' + mode + '" style="float:left;display:none"></i></div>';
      } else {
        stopwatchHtml = '<div class=' + stopwatchColor + '><i class="fa-stopwatch ' + stopwatchColor + ' ' + mode + '" style="float:left"></i></div>';
      }
    }
  }
  if (task.ready_count !== undefined) {
    if(dayStatus === 'planned'){
      taskHtml = '<span class="' + mode + '"><span class="actual-tasks-counter tasks-counter" style="display:none;">' + task.ready_count + '/</span>' + task.total_count + '</span>';
    } else {
      taskHtml = '<span class="' + mode + '"><span class="actual-tasks-counter tasks-counter">' + task.ready_count + '/</span>' + task.total_count + '</span>';
    }
  } else {
    taskHtml = '<span class="' + mode + '"><span class="tasks-counter">' + task.total_count + '</span></span>';
  }

  switch (dayStatus) {
  case 'planned-actual':
    manageActualDaySymbolHtml = '<span><i data-actual-ed-id=' + actualEdId + ' class="fa-actual-check actual-ed-manage"></i></span>';
    break;
  case 'planned':
    manageActualDaySymbolHtml = '<span><i data-actual-ed-id=' + actualEdId + ' class="fa-actual-times actual-ed-manage"></i></span>';
    break;
  case 'actual':
    manageActualDaySymbolHtml = '<span><i data-actual-ed-id=' + actualEdId + ' class="fa-actual-exclamation actual-ed-manage"></i></span>';
    break;
  }

  var taskCounterElement = '<div class="task-common"></div>';
  if(task.grade.match(/Grade 2|3/)) {
    taskCounterElement = '<div class="' + task.state + '-tasks ' + overlapping + ' task-common">' + taskHtml + '</div>' + stopwatchHtml;
  }
  return '<div class="task_template" style="display:flex" data-competency-id=' + competencyId + ' data-task-id=' + taskId + ' data-task-class=' + taskClass + ' data-day-status=' + dayStatus + '><div>' + manageActualDaySymbolHtml + '</div>' + taskCounterElement + '</div>';
}

function wrapAllocatedEmployee(repeatCount, str, employeeDayId, classStyle) {
  return '<div class="' + classStyle + '" data-span="' + repeatCount + '" data-employee-day-id=' + employeeDayId + '>' + str + '</div>';
}

function wrapInnerRow(repeatCount, str) {
  return '<div class="splitted_event" style="">' + str + '</div>';
}

function wrapInnerCell(str) {
  return '<div class="flex-grid-cell"><div class="inner-grid-cell">' + str + '</div></div>';
}
