import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from './Button';
import CheckboxResponse from './completion_mark_forms/CheckboxResponse';
import DateInputResponse from './completion_mark_forms/DateInputResponse';
import FileResponse from './completion_mark_forms/FileResponse';
import InputResponse from './completion_mark_forms/InputResponse';
import NoFormResponse from './completion_mark_forms/NoFormResponse';
import TimeInputResponse from './completion_mark_forms/TimeInputResponse';
import ToggleResponse from './completion_mark_forms/ToggleResponse';

const CompletionMarkForm = (props) => {
  const { formData, resultUrl, csrf_token: csrfToken } = props;
  const [isInvalidValue, setIsInvalidValue] = useState(false);
  const {
    register, handleSubmit, control,
  } = useForm({ mode: 'onChange' });
  const [{ data }, executePost] = useAxios(
    {
      url: '/completion_marks',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    },
    { manual: true },
  );

  const formComponents = {
    CameraResponse: FileResponse,
    CheckboxResponse,
    DateInputResponse,
    FileResponse,
    InputResponse,
    ListOptionsResponse: CheckboxResponse,
    MultiselectResponse: CheckboxResponse,
    NoFormResponse,
    SignatureResponse: FileResponse,
    TimeInputResponse,
    ToggleResponse,
  };
  const FormComponent = formComponents[formData.type];

  if (data) {
    $.ajax({
      url: resultUrl,
      type: 'GET',
      dataType: 'html',
      success(result) {
        $('#form_entries_page').html(result);
        $('#close-dialog-button').click();
      },
    });
  }

  const onSubmit = (requestData) => {
    let requestParams;
    if (FormComponent.name === 'FileResponse') {
      requestParams = new FormData();
      requestParams.append('completion_mark[completion_step_id]', formData.completion_step_id);
      requestParams.append('completion_mark[employee_id]', formData.employee_id);
      requestParams.append('completion_mark[file]', requestData.completion_mark.file[0]);
    } else {
      requestParams = {
        ...requestData,
        completion_mark: {
          ...requestData.completion_mark,
          completion_step_id: formData.completion_step_id,
          employee_id: formData.employee_id,
        },
      };
    }
    executePost({ data: requestParams }).catch(() => setIsInvalidValue(true));
  };

  const handleInputKeyUp = () => setIsInvalidValue(false);

  useEffect(() => {
    window.addEventListener('keyup', handleInputKeyUp);
    return () => window.removeEventListener('keyup', handleInputKeyUp);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <br />
      <Box m={1}>
        <Typography>
          {formData.question}
        </Typography>
      </Box>
      <Box ml={1}>
        <FormComponent
          answerOptions={formData.answer_options}
          answer={formData.answer}
          control={control}
          error={isInvalidValue}
          helperText={isInvalidValue && "Answer can't be blank"}
          register={register}
        />
      </Box>
      <Box m={1}>
        <Button
          type="submit"
          name="Submit"
          variant="contained"
          color="secondary"
          className="text-btn-sml"
        />
      </Box>
    </form>
  );
};
export default CompletionMarkForm;
