import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TableBody from './TableBody';

export default function BaseTable({
  rows, headCells, handleRowClick, customClicksHandler, customCellContentProvider,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Materials">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.key}>{headCell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          rows={rows}
          headCells={headCells}
          handleRowClick={handleRowClick}
          customClicksHandler={customClicksHandler}
          customCellContentProvider={customCellContentProvider}
        />
      </Table>
    </TableContainer>
  );
}
