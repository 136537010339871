import React from 'react';
import PropTypes from 'prop-types';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Tabs = ({ tabs, activeValue }) => {
  const handleChange = (event, newValue) => {
    window.location.href = tabs[newValue].link;
  };

  return (
    <MuiTabs
      value={activeValue}
      variant="scrollable"
      onChange={handleChange}
    >
      { Object.keys(tabs).map((value) => (
        <Tab key={value} value={value} label={tabs[value].label} />
      )) }
    </MuiTabs>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.shape().isRequired,
  activeValue: PropTypes.string.isRequired,
};

export default Tabs;
