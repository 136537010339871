import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from 'react-google-recaptcha';
import useAxios from 'axios-hooks';

import Button from './Button';
import PhoneNumberInput from './PhoneNumberInput';
import AlertSnackbar from './shared/AlertSnackbar';
import { renderError } from '../helpers/errors';
import { EMAIL_REGEX } from '../helpers/field_regex';

export default function ContactUsForm(props) {
  const { csrf_token: csrfToken, captcha_site_key: captchaSiteKey, url } = props;
  const {
    register, handleSubmit, setError, clearErrors, control, formState: { errors },
  } = useForm({
    defaultValues: {
      mob_number: {
        country: '',
        mob_number: '',
      },
    },
    mode: 'onChange',
  });
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const [isCaptchaShown, setIsCaptchaShown] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [, executePost] = useAxios(
    {
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    },
    { manual: true },
  );

  const addServerErrors = (serverErrors) => {
    Object.keys(serverErrors).forEach((key) => setError(`contact_form[${key}]`, { type: 'server' }));
  };

  const onSubmit = (data) => {
    const requestData = data;
    requestData.captcha_token = isCaptchaValid;
    executePost({ data: requestData })
      .then(() => setIsSnackbarShown(true))
      .catch((error) => addServerErrors(error.response.data));
  };

  const hasError = (key) => !!(errors.contact_form && errors.contact_form[key]);

  const handleInputKeyUp = () => {
    setIsCaptchaShown(true);
    clearErrors(['contact_form[email_or_phone_number]', 'contact_form[base]']);
  };

  useEffect(() => {
    window.addEventListener('keyup', handleInputKeyUp);
    return () => window.removeEventListener('keyup', handleInputKeyUp);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="contact_form[fullname]"
        label="Full Name"
        margin="normal"
        {...register('contact_form[fullname]')}
      />
      <TextField
        name="contact_form[email]"
        label="Email"
        margin="normal"
        error={hasError('email') || hasError('email_or_phone_number')}
        helperText={hasError('email') && 'Invalid email'}
        {...register('contact_form[email]', { pattern: EMAIL_REGEX })}
      />
      <Controller
        control={control}
        name="contact_form[mob_number]"
        defaultValue=""
        render={({ onChange }) => (
          <PhoneNumberInput
            formName="contact_form"
            phoneNumberKey="mob_number"
            label="Phone number"
            margin="normal"
            error={hasError('mob_number') || hasError('email_or_phone_number')}
            helperText={hasError('mob_number') && 'Invalid phone number'}
            onChange={(v) => onChange(v)}
          />
        )}
      />
      <TextField
        name="contact_form[message]"
        label="Message"
        margin="normal"
        multiline
        rows="6"
        error={hasError('message')}
        helperText={hasError('message') && 'Message is required'}
        {...register('contact_form[message]', { required: true })}
      />
      <ErrorMessage
        errors={errors}
        name="contact_form[email_or_phone_number]"
        message="Email or phone number should be filled"
        render={({ message }) => renderError(message)}
      />
      <ErrorMessage
        errors={errors}
        name="contact_form[base]"
        message="Invalid captcha"
        render={({ message }) => renderError(message)}
      />
      { isCaptchaShown
              && <div className="captcha"><ReCAPTCHA sitekey={captchaSiteKey} onChange={setIsCaptchaValid} /></div>}
      <Button
        type="submit"
        name="Submit"
        variant="contained"
        className="text-btn-sml"
        disabled={!isCaptchaValid}
      />
      <AlertSnackbar
        open={isSnackbarShown}
        message="We will be in contact soon"
        onClose={() => setIsSnackbarShown(false)}
      />
    </form>
  );
}
