import React from 'react';
import BaseStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';

export default function Stepper(props) {
  const { steps, activeStep } = props;
  const isSmall = useMediaQuery('(max-width:767px)');

  return (
    <Paper>
      <BaseStepper activeStep={activeStep} connector={null} orientation={isSmall ? 'vertical' : 'horizontal'}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </BaseStepper>
    </Paper>
  );
}
