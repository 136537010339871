import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

export default function Multiselect(props) {
  const {
    options, inputName, inputId, defaultValue, placeholder = 'Choose options',
  } = props;
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl variant="standard">
      <Select
        variant="standard"
        multiple
        displayEmpty
        value={value}
        onChange={handleChange}
        inputProps={{ name: inputName, id: inputId, multiple: true }}
        renderValue={(selected) => {
          $(document.getElementById(inputId)).trigger('change');
          if (selected.length === 0) {
            return <em>{placeholder}</em>;
          }
          return selected.join(', ');
        }}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} color="primary" />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
