'use strict';
import { parseTime } from './helpers';

export function plantActivityChartPdf(options) {
	var chartId = options.chartId;
	var data    = options.data.graph;  // Setup

	var margin = {
		top:    8,
		right:  8,
		bottom: 8,
		left:   8
	};

	var height                 = 700;
	var timeColumnWidth        = 32;
	var timeColumnPaddingWidth = 8;
	var actsColumnTickWidth    = 24;
	var actsColumnWidth        = 48;
	var actGroupsColumnWidth   = 240;
	var width                  = timeColumnWidth + timeColumnPaddingWidth + actsColumnTickWidth + actsColumnWidth + actGroupsColumnWidth;
	var svgWidth               = width + margin.left + margin.right;
	var svgHeight              = height + margin.top + margin.bottom;
	var actGroupsLabelPadding  = 8;                                                                                                        // Process data

	var parseTime        = d3.timeParse("%H:%M");
	var formatTime       = d3.timeFormat("%H:%M");

	data.shift_start = parseTime(data.shift_start);
	data.shift_end   = parseTime(data.shift_end);

	data.acts        = data.acts.map(function (d) {
		d.start = parseTime(d.start);
		d.end   = parseTime(d.end);
		return d;
	});

	data.lifts 			= data.lifts.map(parseTime);
	data.act_groups = data.act_groups.map(function (d) {
		d.start = parseTime(d.start);
		d.end   = parseTime(d.end);
		return d;
	}); // Scales and axes

	var y     = d3.scaleTime().domain([data.shift_start, data.shift_end]).range([0, height]);
	var yAxis = d3.axisLeft(y).tickFormat(formatTime).tickPadding(timeColumnPaddingWidth).tickSizeOuter(0).tickSizeInner(actsColumnTickWidth + actsColumnWidth);  // Containers

	var container = d3.select("#".concat(chartId)).classed("plant-activity-chart", true);
	var svg       = container.append("svg").attr("width", svgWidth).attr("height", svgHeight);
	var g         = svg.append("g").attr("transform", "translate(".concat(margin.left, ",").concat(margin.top, ")"));
	var tooltip   = container.append("div").attr("class", "chart-tooltip");                                            // Time column

	var gTime = g.append("g").attr("class", "axis").attr("transform", "translate(".concat(timeColumnWidth + timeColumnPaddingWidth + actsColumnTickWidth + actsColumnWidth, ",0)")).call(yAxis); // Acts column

	var gActs = g.append("g").attr("class", "acts").attr("transform", "translate(".concat(timeColumnWidth + timeColumnPaddingWidth + actsColumnTickWidth, ",0)")); // Track lines

	gActs.append("g").selectAll("line").data([0, actsColumnWidth]).join("line").attr("class", "acts-track-line").attr("x1", function (d) {
		return d;
	}).attr("x2", function (d) {
		return d;
	}).attr("y2", height); // Rects

	gActs.append("g").selectAll("rect").data(data.acts).join("rect").attr("y", function (d) {
		return y(d.start);
	}).attr("width", actsColumnWidth).attr("height", function (d) {
		return y(d.end) - y(d.start);
	}).attr("class", function (d) {
		return "act-rect ".concat(d.type.toLowerCase());
	}); // Crosses

	gActs.append("g").selectAll("text").data(data.lifts).join("text").attr("x", actsColumnWidth / 2).attr("y", function (d) {
		return y(d);
	}).attr("dy", "0.32em").attr("text-anchor", "middle").attr("font-size", 10).text("×"); // Act groups column

	var gActGroups = g.append("g").attr("class", "act-groups").attr("transform", "translate(".concat(timeColumnWidth + timeColumnPaddingWidth + actsColumnTickWidth + actsColumnWidth, ",0)")); // Rects

	gActGroups.append("g").selectAll("rect").data(data.act_groups).join("rect").attr("class", "act-group-rect").attr("y", function (d) {
		return y(d.start);
	}).attr("width", actGroupsColumnWidth).attr("height", function (d) {
		return y(d.end) - y(d.start);
	}).on("mouseenter", showTooltip).on("mouseleave", hideTooltip).on("mousemove", moveTooltip); // Labels
	// Add a clip to clip off long text

	gActGroups.append("clipPath").attr("id", "".concat(chartId, "-clip")).append("rect").attr("x", actGroupsLabelPadding).attr("width", actGroupsColumnWidth - actGroupsLabelPadding * 2).attr("height", height);
	gActGroups.append("g").attr("clip-path", "url(#".concat(chartId, "-clip)")).selectAll("text").data(data.act_groups).join("text").attr("class", "act-group-label").attr("x", actGroupsLabelPadding).attr("y", function (d) {
		return (y(d.end) + y(d.start)) / 2;
	}).attr("dy", "0.32em").text(function (d) {
		return d.Text;
	}); // Tooltip

	function showTooltip(d) {
		d3.select(this).classed("active", true);
		tooltip.style("opacity", 1).text(d.Text);
	}

	function hideTooltip() {
		d3.select(this).classed("active", false);
		tooltip.style("opacity", 0).text("");
	}

	function moveTooltip() {
		var pos = d3.clientPoint(container.node(), d3.event);
		tooltip.style("transform", "translate(".concat(pos[0] + 8, "px,").concat(pos[1] + 8, "px)"));
	}
}
window.plantActivityChartPdf = plantActivityChartPdf;
