import '@fortawesome/fontawesome-free/js/all';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactRailsUJS from 'react_ujs';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import theme from '../styles/Theme';

import '../src/landing_page/contact_us.js';
import '../src/landing_page/mobile_menu.js';
import '../src/landing_page/reviews.js';

// modified from
// https://github.com/reactjs/react-rails/blob/master/react_ujs/index.js#L83-L121
// and
// https://github.com/souporserious/react-rails-theme-provider/blob/main/app/javascript/packs/application.js

ReactRailsUJS.mountComponents = (searchSelector) => {
  const ujs = ReactRailsUJS;
  const nodes = ujs.findDOMNodes(searchSelector);

  nodes.forEach((node) => {
    const className = node.getAttribute(ujs.CLASS_NAME_ATTR);
    const constructor = ujs.getConstructor(className);
    const propsJson = node.getAttribute(ujs.PROPS_ATTR);
    const props = propsJson && JSON.parse(propsJson);
    const hydrate = node.getAttribute(ujs.RENDER_ATTR);
    const cacheId = node.getAttribute(ujs.CACHE_ID_ATTR);
    const turbolinksPermanent = node.hasAttribute(ujs.TURBOLINKS_PERMANENT_ATTR);

    if (!constructor) {
      const message = `Cannot find component: '${className}'`;
      if (console && console.log) {
        console.log(
          `%c[react-rails] %c${message} for element`,
          'font-weight: bold',
          '',
          node,
        );
      }
      throw new Error(
        `${message}. Make sure your component is available to render.`,
      );
    } else {
      let component = ujs.components[cacheId];
      if (component === undefined) {
        component = React.createElement(constructor, props);
        if (turbolinksPermanent) {
          ujs.components[cacheId] = component;
        }
      }

      if (hydrate && typeof ReactDOM.hydrate === 'function') {
        component = ReactDOM.hydrate(
          (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>{component}</ThemeProvider>
            </StyledEngineProvider>
          ),
          node,
        );
      } else {
        component = ReactDOM.render(
          (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>{component}</ThemeProvider>
            </StyledEngineProvider>
          ),
          node,
        );
      }
    }
  });
};

const componentRequireContext = require.context('components', true);
ReactRailsUJS.useContext(componentRequireContext);
