import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import './styles.css';

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, ':', /[0-6]/, /\d/]}
      placeholder="hh:mm"
      showMask
    />
  );
});

TextMaskCustom.displayName = 'TextMaskCustom';

const stringifyDuration = (duration) => {
  if (!duration) return null;
  const { hours, minutes } = duration;
  let result = '';
  if (hours < 10) {
    result += 0;
  }
  result = `${result}${hours}:`;
  if (minutes < 10) {
    result += 0;
  }
  result += minutes;
  return result;
};

const DurationInput = React.forwardRef((props, ref) => {
  const {
    value,
    inputId,
    inputName,
    disabled,
    handleChange: updateDuration,
  } = props;

  const [durationValue, setDurationValue] = useState(stringifyDuration(value));

  useEffect(() => {
    setDurationValue(stringifyDuration(value));
  }, [value]);

  const handleChange = (event) => {
    setDurationValue(event.target.value);
  };

  return (
    <Box>
      <Input
        value={durationValue}
        onBlur={() => updateDuration(durationValue)}
        onChange={handleChange}
        inputProps={{ id: inputId, name: inputName, ref }}
        inputComponent={TextMaskCustom}
        disabled={disabled}
        disableUnderline
      />
    </Box>
  );
});

DurationInput.displayName = 'DurationInput';
export default DurationInput;
