import React from 'react';

import Tooltip from './Tooltip';
import iconUpdated from '../../../../assets/images/icon-updated.svg';
import iconOperator from '../../../../assets/images/icon-operator.svg';
import iconActivity from '../../../../assets/images/icon-activity.svg';

export default function PlanHeaderTooltip(props) {
  const { type } = props;
  let title;
  let image;

  switch (type) {
    case 'lastUpdated':
      title = 'Plan last updated';
      image = iconUpdated;
      break;
    case 'operator':
      title = 'Operator';
      image = iconOperator;
      break;
    case 'lastActivity':
      title = 'Operators last activity';
      image = iconActivity;
      break;
    default:
      break;
  }
  const html = `<img src=${image}>`;

  return <Tooltip title={title} html={html} />;
}
