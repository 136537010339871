import React from 'react';
import TextField from '@mui/material/TextField';

const FileResponse = (props) => (
  <TextField
    variant="standard"
    name="completion_mark[file]"
    type="file"
    {...props}
  />
);
export default FileResponse;
