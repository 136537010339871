import React from 'react';
import MaterialUiTooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import {
  lightGrey,
  darkGreyBlue,
  white,
} from '../../../../assets/stylesheets/old_styles/colors.scss';

export default function Tooltip(props) {
  const {
    title,
    placement = 'right',
    html,
    children,
  } = props;
  let tooltipChildren;
  if (children) {
    tooltipChildren = children;
  } else {
    tooltipChildren = (
      /* eslint-disable react/no-danger */
      <div dangerouslySetInnerHTML={{ __html: html }} />
      /* eslint-enable react/no-danger */
    );
  }
  const BaseTooltip = withStyles({
    arrow: {
      '&:before': {
        border: `1px solid ${lightGrey}`,
      },
      color: darkGreyBlue,
    },
    tooltip: {
      color: white,
      backgroundColor: darkGreyBlue,
      border: `1px solid ${lightGrey}`,
      fontSize: '13px',
      fontWeight: '700',
      padding: '14px',
    },
  })(MaterialUiTooltip);
  return (
    <BaseTooltip title={<span style={{ whiteSpace: 'pre-line' }}>{title}</span>} placement={placement} arrow>
      {tooltipChildren}
    </BaseTooltip>
  );
}
