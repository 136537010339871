import React from 'react';
import BaseButton from '@mui/material/Button';

import theme from '../styles/Theme';

export default function Button(props) {
  const { className = 'text-btn-lg', name } = props;
  return (
    <BaseButton {...props} classes={{ label: className }}>
      {name}
    </BaseButton>
  );
}
