import React from 'react';
import TextField from '@mui/material/TextField';

const TimeInputResponse = (props) => {
  const { answer } = props;
  return (
    <TextField
      variant="standard"
      name="completion_mark[options][answer]"
      defaultValue={answer}
      type="time"
      {...props}
    />
  );
};
export default TimeInputResponse;
