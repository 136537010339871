import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import MaterialTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';

const TableHead = (props) => {
  const {
    order, orderBy, onRequestSort, onRequestSearch, headCells, searchVisible,
  } = props;

  const handleSort = (attributeName) => (event) => {
    onRequestSort(event, attributeName);
  };

  const handleChange = (attributeName) => (event) => {
    onRequestSearch(event, attributeName);
  };

  return (
    <MaterialTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            {searchVisible && (
            <TextField
              variant='standard'
              onChange={handleChange(headCell.key)}
              name={headCell.key}
            />
            )}
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={handleSort(headCell.key)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MaterialTableHead>
  );
};

TableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default TableHead;
