import React from 'react';
import ReactDOM from 'react-dom';
import { format } from 'date-fns';

import { plannedExecutionGraph } from './planned-execution-graph';
import { plantActivityChartPdf } from './plant-activity-chart-pdf';
import { sectionGraph } from './section-graph';
import {
  clickEventType,
  showSpinner,
  changeDateByDays,
  initializeZTimeP,
  parseTime,
  convertTime12to24,
  UserPreferences,
} from './helpers';

import DatePicker from '../components/common/DatePicker';

function updateKpiTimeTarget(projectId, kpiTargetId, time24h) {
  $.ajax({
    url: `/projects/${projectId}/kpi_targets/${kpiTargetId}`,
    type: 'PATCH',
    data: { kpi_target: { value: time24h } },
    success() {
      window.location.reload();
    },
  });
}

function handleKpiTimeTargetClick(projectId, kpiTargetId, targetTime) {
  const parsedTime = parseTime(targetTime);
  const options = {
    hour: parsedTime.hour,
    min: parsedTime.min,
    ampm: parsedTime.ampm,
    duration: false,
    clickEvents: {
      onSave(h, m, ampm) {
        const time12h = `${h}:${m} ${ampm}`;
        const time24h = convertTime12to24(time12h);
        updateKpiTimeTarget(projectId, kpiTargetId, time24h);
      },
    },
  };
  initializeZTimeP(options);
  $('.ztimep-open').trigger(clickEventType);
}

function handleKpiTargetClick(projectId, kpiTargetId) {
  $.ajax({
    url: `/projects/${projectId}/kpi_targets/${kpiTargetId}/edit.js`,
    type: 'GET',
    success() {
      $('#edit-kpi-target').dialog({
        modal: true,
        title: 'Edit KPI target',
        zIndex: 10000,
        autoOpen: true,
        width: 'auto',
        resizable: false,
        close() {
          $(this).dialog('destroy');
        },
      });
    },
  });
}

export { handleKpiTimeTargetClick, handleKpiTargetClick };

$(document).ready(() => {
  const url = window.location.href;
  if (url.split('/')[5] === 'kpis') {
    $('.report-type')[0].checked = true;
  }

  $(document).on(clickEventType, '.report-type', function () {
    $('#kpis_section').hide();
    $('#kpis_monthly_section').hide();
    $('#kpis_weekly_section, #plants').hide();

    const d = new Date();
    if ($(this).is(':checked') && $(this).val() === 'Weekly') {
      $('#kpis_weekly_section, #plants').show();
      $('#kpis-datepicker').datepicker('option', 'maxDate', null);
      $('#kpis-datepicker').datepicker('setDate', d);
      setCranesAndIds();
      updateGraph();
    } else if ($(this).val() === 'Daily') {
      $('#kpis_section').show();
      viewPlannedActivitiesCharts();
      $('#kpis-datepicker').datepicker('option', 'maxDate', null);
      $('#kpis-datepicker').datepicker('setDate', d);
      updatePlannedExecutionGraphs();
    } else if ($(this).val() === 'Regional Overview') {
      $('#kpis_monthly_section').show();
      d.setDate(d.getDate() - 1);
      const prevMonthLastDay = new Date();
      prevMonthLastDay.setDate(0);
      $('#kpis-datepicker').datepicker('option', 'maxDate', d);
      $('#kpis-datepicker').datepicker('setDate', prevMonthLastDay);
      showMonthlyResults();
    }
  });

  function kpiReportType() {
    return $('.report-type:checked').val();
  }

  $(document).on('click', '.planned-activities-radio', () => {
    viewPlannedActivitiesCharts();
  });

  function viewPlannedActivitiesCharts() {
    const prefs = new UserPreferences();
    prefs.remember('selectedKPIChart', 'planned');

    $('.planned-activities-radio').prop('checked', true);
    $('.actual-activities-radio').prop('checked', false);
    $('.detailed-activities-radio').prop('checked', false);

    $('.planned-execution-graphs').removeClass('hidden');
    $('.actual-activities-graphs').addClass('hidden');
    $('.detailed-activities-table').addClass('hidden');
    updatePlannedExecutionGraphs();
  }

  $(document).on('click', '.actual-activities-radio', () => {
    viewActualActivitiesGraphs();
  });

  function viewActualActivitiesGraphs() {
    const prefs = new UserPreferences();
    prefs.remember('selectedKPIChart', 'actual');

    $('.actual-activities-radio').prop('checked', true);
    $('.planned-activities-radio').prop('checked', false);
    $('.detailed-activities-radio').prop('checked', false);

    $('.planned-execution-graphs').addClass('hidden');
    $('.actual-activities-graphs').removeClass('hidden');
    $('.detailed-activities-table').addClass('hidden');
    updateActualActivitiesGraph();
  }

  $(document).on('click', '.detailed-activities-radio', () => viewDetailedActivities());

  function viewDetailedActivities() {
    const prefs = new UserPreferences();
    prefs.remember('selectedKPIChart', 'detailed');

    $('.detailed-activities-radio').prop('checked', true);
    $('.planned-activities-radio').prop('checked', false);
    $('.actual-activities-radio').prop('checked', false);

    $('.planned-execution-graphs').addClass('hidden');
    $('.actual-activities-graphs').addClass('hidden');
    $('.detailed-activities-table').addClass('hidden');
    showDetailedActivities();
  }

  function showDetailedActivities() {
    const projectId = $('#project_id').data('projectId');
    const date = $('#kpis-datepicker').val();
    const data = { project_id: projectId, date };
    $.ajax({
      url: '/actual_plant_activities.js',
      type: 'GET',
      data,
      beforeSend() { showSpinner(); },
      complete() { $('.spinner').hide(); },
    });
  }

  $(document).on(clickEventType, '#change_kpi_date', () => {
    if (kpiReportType() === 'Regional Overview') {
      showKpiReportDateRange();
    } else {
      showKpiReportDatePicker();
    }
  });

  function showKpiReportDatePicker() {
    const datePicker = document.querySelector('#date-picker');
    const date = $('#kpis-datepicker').val();
    ReactDOM.unmountComponentAtNode(datePicker);
    ReactDOM.render(
      <DatePicker date={date} open applyDate={(value) => applyKpiDate(value)} />,
      datePicker,
    );
  }

  function showKpiReportDateRange() {
    $('#date-range-popup').dialog({
      modal: true,
      width: 'auto',
      autoOpen: true,
      title: 'Select date range',
      resizable: false,
      close() { $(this).dialog('close'); },
    });
  }

  $(document).on('click', '#confirm-date-range', () => {
    $('#date-range-popup').dialog('destroy');
    showMonthlyResults();
  });

  $(document).on('change', '.plant', () => {
    const plants = $('.plant:checked').map(function () {
      return $(this).val();
    }).get();
    if (plants.length === 1) {
      $('#selected_plants').text(plants[0]);
    } else {
      $('#selected_plants').text(`Average of ${plants.join(', ')}`);
    }
    updateGraph();
  });

  $(document).on('click', '#weekly-pdf', () => {
    const projectId = $('#project_id').data('projectId');
    const date = $('#kpis-datepicker').val();
    const selectedPlants = JSON.parse(localStorage.getItem('selectedPlantsKPI'));
    const plantList = [];

    selectedPlants.forEach((plant) => {
      plantList.push(plant.id);
    });

    const data = {
      plant_ids: plantList,
      date,
    };
    const params = $.param(data);
    window.location.href = `/projects/${projectId}/kpis/pdf_report.pdf?${params}`;
  });

  function addParamForActualActivitiesGraph(pdfDownloadParams) {
    if ($('.actual-activities-radio').is(':checked')) {
      pdfDownloadParams.type = 'actual';
    }

    return pdfDownloadParams;
  }

  $(document).on('click', '#daily-pdf', () => {
    const projectId = $('#project_id').data('projectId');
    const date = $('#kpis-datepicker').val();
    const plantIds = getPlantIdsFromKpisPage();

    let data = {
      plant_ids: plantIds,
      date,
    };
    data = addParamForActualActivitiesGraph(data);

    if (data.type !== undefined) {
      followDailyPdfLink(projectId, data);
    } else {
      $('#pdf-dialog').dialog({
        modal: true,
        autoOpen: true,
        width: '500px',
        resize: 'none',
        buttons: [
          {
            text: 'Yes',
            click() {
              data.notes_present = true;
              followDailyPdfLink(projectId, data);
              $(this).dialog('destroy');
            },
          },
          {
            text: 'No',
            click() {
              followDailyPdfLink(projectId, data);
              $(this).dialog('destroy');
            },
          },
        ],
      });
    }
  });

  function followDailyPdfLink(projectId, data) {
    const params = $.param(data);
    window.location.href = `/projects/${projectId}/kpis/pdf_daily_report.pdf?${params}`;
  }

  $(document).on('click', '#monthly-pdf', () => {
    const projectId = $('#project_id').data('projectId');
    const startDate = $('#date-range-popup #start_date').val();
    const endDate = $('#date-range-popup #end_date').val();
    const params = $.param({ start_date: startDate, end_date: endDate });
    window.location.href = `/projects/${projectId}/kpis/data_for_monthly_results.pdf?${params}`;
  });

  function applyKpiDate(date) {
    const dateText = format(date, 'dd/MM/yyyy');
    const projectId = $('#project_id').data('projectId');
    $('#date').text(dateText);
    $('#kpis-datepicker').val(dateText);

    $.ajax({
      url: `/projects/${projectId}/kpis.js`,
      type: 'GET',
      data: { date: dateText },
      beforeSend() {
        showSpinner();
      },
      complete() {
        $('.spinner').hide();
        const prefs = new UserPreferences();
        const selectedKPIChart = prefs.fetch('selectedKPIChart');

        if (selectedKPIChart === 'planned') {
          viewPlannedActivitiesCharts();
        } else if (selectedKPIChart === 'actual') {
          viewActualActivitiesGraphs();
        } else if (selectedKPIChart === 'detailed') {
          viewDetailedActivities();
        }

        const reportType = $('.report-type:checked').val();
        if (reportType === 'Weekly') {
          $('#end-of-week').text('');
          updateGraph();
        } else if (reportType === 'Monthly') {
          showMonthlyResults();
        }
      },
    });
  }

  $(() => {
    $('#kpis_section #plant_id').chosen();
    updatePlannedExecutionGraphs();
  });

  $(document).on(clickEventType, '#choose-kpi-cranes', () => {
    const projectId = $('#kpis_weekly_section #project_id').data('projectId');

    $.ajax({
      url: `/projects/${projectId}/available_plants.js`,
      type: 'GET',
      success() {
        const plants = JSON.parse(localStorage.getItem('selectedPlantsKPI'));
        plants.forEach((plant) => {
          $(`input:checkbox#plant-id-checkbox-${plant.id}`).attr('checked', 'checked');
        });
      },
    });
  });

  $(document).on('click', '#submit-kpi-crane-selection', (e) => {
    e.preventDefault();
    const plantIds = [];

    $('#choose-kpi-cranes-form #plant-checkboxes :checkbox:checked').each(function () {
      plantIds.push($(this).val());
    });

    $('.kpi-report-header__plants--form-information #selected_plants').attr('data-selected-plant-ids', plantIds.join(','));
    $('#choose-kpi-cranes-form').dialog('destroy');
    $('#choose-kpi-cranes-form').empty();

    updateGraph();
  });

  function setCranesAndIds() {
    const plantIds = $('.kpi-report-header__plants--form-information #selected_plants').attr('data-selected-plant-ids').split(',');
    const plants = $('.kpi-report-header__plants--form-information #selected_plants').attr('data-selected-plants').split('||');
    const allPlants = [];

    for (let index = 0; index < plantIds.length; index++) {
      allPlants.push({ id: plantIds[index], name: plants[index] });
    }

    localStorage && localStorage.setItem('allPlantsKPI', JSON.stringify(allPlants));
  }

  function setCorrectCraneSelectionNames() {
    const allPlants = JSON.parse(localStorage.getItem('allPlantsKPI'));
    const plantIds = $('.kpi-report-header__plants--form-information #selected_plants').attr('data-selected-plant-ids').split(',');
    const selectedPlantsObject = [];
    const selectedPlants = [];

    allPlants.map((plant, index) => {
      plantIds.map((id) => {
        if (plant.id === id) {
          selectedPlantsObject.push(plant);
          selectedPlants.push(plant.name);
        }
      });
    });

    localStorage && localStorage.setItem('selectedPlantsKPI', JSON.stringify(selectedPlantsObject));
    $('.kpi-report-header__plants--form-information #selected_plants').html(`Average of ${selectedPlants.join(', ')}`);
  }

  function updateGraph() {
    $('.section-graph svg, .graph-tooltip').remove();

    const projectId = $('#project_id').data('projectId');
    const date = $('#kpis-datepicker').val();
    const plantIds = $('.kpi-report-header__plants--form-information #selected_plants').attr('data-selected-plant-ids');
    const data = { plant_ids: plantIds, date };

    $.ajax({
      url: `/projects/${projectId}/kpis/data_for_weekly_graphs.json`,
      type: 'GET',
      data,
      beforeSend() { showSpinner(); },
      complete() { $('.spinner').hide(); },
      success(jsonData) {
        setCorrectCraneSelectionNames();
        $('#end-of-week').text(jsonData.end_of_week);

        jsonData.graphs.forEach((graph) => {
          sectionGraph({
            containerId: 'section-graph',
            dataURL: graph,
          });
        });
      },
    });
  }

  function updatePlannedExecutionGraphs() {
    if ($('#kpis_section').length === 0) { return; }

    $('.actual-activities-graphs svg').remove();
    $('.planned-execution-graph svg').remove();

    const plantIds = getPlantIdsFromKpisPage();
    const projectId = $('#project_id').data('projectId');
    const date = $('#kpis-datepicker').val();
    const data = { plant_ids: plantIds, date };

    $.ajax({
      url: `/projects/${projectId}/kpis/data_for_graph.json`,
      type: 'GET',
      data,
      beforeSend() { showSpinner(); },
      complete() { $('.spinner').hide(); },
      success(jsonData) {
        $('.planned-execution-graphs').removeClass('hidden');

        jsonData.graphs.forEach((craneGraph) => {
          plannedExecutionGraph({
            containerId: `planned-execution-graph-${craneGraph.plant_id}`,
            data: craneGraph,
          });
        });

        if (jsonData.graphs.length === 0) {
          $('#project_id').append('<p>No cranes available<p>');
        }
      },
    });
  }

  function updateActualActivitiesGraph() {
    if ($('#kpis_section').length === 0) { return; }

    $('.actual-activities-graphs svg').remove();
    $('.planned-execution-graph svg').remove();

    const plantIds = getPlantIdsFromKpisPage();
    const projectId = $('#project_id').data('projectId');
    const date = $('#kpis-datepicker').val();
    const data = {
      plant_ids: plantIds,
      date,
      type: 'actual',
    };

    $.ajax({
      url: `/projects/${projectId}/kpis/data_for_graph.json`,
      type: 'GET',
      data,
      beforeSend() { showSpinner(); },
      complete() { $('.spinner').hide(); },
      success(jsonData) {
        $('.actual-activities-graphs').removeClass('hidden');

        jsonData.graphs.forEach((craneGraph) => {
          plantActivityChartPdf({
            chartId: `actual-activities-graph-${craneGraph.plant_id}`,
            data: craneGraph,
          });
        });
      },
    });
  }

  function getPlantIdsFromKpisPage() {
    return $('.kpis-table th').map(function () {
      return $(this).data('plantId');
    }).toArray();
  }

  function showMonthlyResults() {
    const projectId = $('#project_id').data('projectId');
    const startDate = $('#date-range-popup #start_date').val();
    const endDate = $('#date-range-popup #end_date').val();
    $.ajax({
      url: `/projects/${projectId}/kpis/data_for_monthly_results`,
      type: 'GET',
      data: { start_date: startDate, end_date: endDate },
      beforeSend() { showSpinner(); },
      complete() { $('.spinner').hide(); },
      success(data) {
        $('#kpis_monthly_results').html(data);
      },
    });
  }
});
