$(document).ready(function () {
  $(function () {
    setTimeout(function () {
      $('#flash').html('');
    }, 30000);
  });

  $(document).on('DOMSubtreeModified', 'div[id=flash], #notifications-errors, #errors', function () {
    setTimeout(function () {
      $('div[id=flash], #notifications-errors, #errors').html('');
    }, 30000);
  });
});

export const showFlashMessage = function(message, type, selector) {
  type     = typeof type     !== 'undefined' ? type     : 'danger';
  selector = typeof selector !== 'undefined' ? selector : '#flash';

  var messageHtml = "<div class='alert-" + type + "'>" + message + '</div>';

  $(selector).html(messageHtml);
}
window.showFlashMessage = showFlashMessage;
