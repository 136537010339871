import { createTheme } from '@mui/material/styles';

import {
  white100,
  linknblue100,
  ctaCanary100,
  black200,
  black300,
  black400,
  black500,
  black600,
  slate100,
} from '../../assets/stylesheets/new_styles/colors.scss';
import { headerFontFamily } from '../../assets/stylesheets/new_styles/brand_guide.scss';

export default createTheme({
  palette: {
    primary: {
      main: linknblue100,
    },
    secondary: {
      main: ctaCanary100,
    },
    black300,
    black500,
    black600,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          borderRadius: 30,
          margin: 'auto',
          padding: '15px 35px',
        },
        label: {
          color: white100,
        },
        contained: {
          boxShadow: 'none',
        },
        containedSecondary: {
          '&& span': {
            color: linknblue100,
          },
        },
        outlinedPrimary: {
          border: `2px solid ${linknblue100}`,
          padding: '13px 35px',
          '&:hover': {
            border: `2px solid ${linknblue100}`,
          },
          '&& span': {
            color: linknblue100,
          },
        },
        focusVisible: '',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: white100,
          borderRadius: 6,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '16px 14px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 0,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPhoneNumber: {
      styleOverrides: {
        flagButton: {
          height: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: headerFontFamily,
          textTransform: 'none',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '22px',
          textAlign: 'center',
          letterSpacing: '-0.1px',
          color: slate100,
          backgroundColor: white100,
          padding: 10,
          borderBottom: `2px solid ${black400}`,
          minWidth: 100,
          '@media (min-width: 600px)': {
            minWidth: 100,
          },
          '&:hover': {
            opacity: 1,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${black600}`,
          '&:last-child': {
            borderRadius: 6,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: black200,
          fontSize: 15,
          lineHeight: '22px',
          letterSpacing: -0.2,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          backgroundColor: black500,
        },
        input: {
          color: black200,
          fontSize: 15,
          lineHeight: '22px',
          letterSpacing: -0.2,
          padding: '9px 15px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        selectMenu: {
          height: 'unset',
        },
        root: {
          borderRadius: 6,
          backgroundColor: black500,
        },
        select: {
          borderRadius: 6,
          '&:focus': {
            borderRadius: 6,
          },
        },
      },
    },
  },
});
