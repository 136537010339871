import React from 'react';
import HistoryIcon from '@mui/icons-material/History';

const Icon = (props) => {
  const { type } = props;
  const components = {
    history: HistoryIcon,
  };
  const IconComponent = components[type];
  return <IconComponent />;
};
export default Icon;
