import { plantPlanner } from './plans';

const navigationSelector = '.plants-navigation';
let firstVisiblePlantIndex = 0;

const getPlantsLimit = () => ($(window).width() <= 768 ? 1 : (($('#plants-info').children().length - firstVisiblePlantIndex) || 256));

const plantColumns = () => $('#content-plan-form > #plants-info > div');

const isNavigationVisible = () => !$(navigationSelector).hasClass('hidden');

const needNavigation = () => plantColumns().length > getPlantsLimit();

const plantIdsFromPlanner = () => plantColumns().map((i, div) => div.getAttribute('data-plant-id')).toArray();

const canMoveRight = () => firstVisiblePlantIndex !== plantColumns().length - getPlantsLimit();

const canMoveLeft = () => firstVisiblePlantIndex !== 0;

function setPlants() { plantPlanner.refetchEvents(); }

function rollPlantsInfosColumnNumbers(rollValue) {
  plantColumns().each((index, plant) => {
    const columnNumber = parseInt(plant.getAttribute('data-column'), 10);
    plant.setAttribute('data-column', columnNumber + rollValue);
  });
}

function setButtonVisibility(selector, canMove) {
  const button = $(selector);

  if (canMove) {
    if (button.hasClass('hidden')) { button.removeClass('hidden'); }
  } else if (!button.hasClass('hidden')) {
    button.addClass('hidden');
  }
}

function setButtonsVisibility() {
  setButtonVisibility('#plants-navigation-right', canMoveRight());
  setButtonVisibility('#plants-navigation-left', canMoveLeft());
}

function moveRight() {
  if (!canMoveRight()) {
    return;
  }

  firstVisiblePlantIndex += 1;
  rollPlantsInfosColumnNumbers(-1);
  setButtonsVisibility();
  setPlants();
}

function moveLeft() {
  if (!canMoveLeft()) {
    return;
  }

  firstVisiblePlantIndex -= 1;
  rollPlantsInfosColumnNumbers(1);
  setButtonsVisibility();
  setPlants();
}

function initCallbacks() {
  $('#plants-navigation-right').on('click', () => {
    moveRight();
  });

  $('#plants-navigation-left').on('click', () => {
    moveLeft();
  });
}

function updateNavigationVisibility() {
  if (needNavigation()) {
    if (!isNavigationVisible()) { $(navigationSelector).removeClass('hidden'); }
    setButtonsVisibility();
  } else if (isNavigationVisible()) { $(navigationSelector).addClass('hidden'); }
}

export function initNavigation() {
  if ($('.plants-navigation').length === 0) {
    $('.fc-row.fc-widget-header').append(
      '<div class="plants-navigation hidden">'
      + '<div><div class="navigation-item plants-navigation-button" id="plants-navigation-left">&#60;</div></div>'
      + '<div><div class="navigation-item plants-navigation-button" id="plants-navigation-right">&#62;</div></div>'
      + '</div>',
    );
    initCallbacks();
  }
  updateNavigationVisibility();
}

export function updateNavigation() {
  firstVisiblePlantIndex = 0;

  updateNavigationVisibility();
}

export function getVisiblePlantIds() {
  return plantIdsFromPlanner().slice(firstVisiblePlantIndex, firstVisiblePlantIndex + getPlantsLimit());
}
