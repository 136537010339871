import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutocompleteInput(props) {
  const {
    name, values, defaultValue, groups, onChangeCallbackName,
  } = props;

  const onChangeValue = (event, value) => {
    if (event !== null && onChangeCallbackName) {
      window[onChangeCallbackName](value);
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={values}
      defaultValue={defaultValue}
      groupBy={(option) => groups[option]}
      onInputChange={onChangeValue}
      renderInput={(params) => (
        <TextField {...params} name={name} />
      )}
    />
  );
}
