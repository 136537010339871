import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';

import Box from '@mui/material/Box';
import Button from './Button';
import AlertSnackbar from './shared/AlertSnackbar';
import EmailOrPhoneNumberInput from './EmailOrPhoneNumberInput';

export default function ForgotPasswordForm(props) {
  const { csrf_token: csrfToken } = props;
  const [isInvalidValue, setIsInvalidValue] = useState(false);
  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm({ mode: 'onChange' });
  const [{ data }, executePost] = useAxios(
    {
      url: '/password_resets',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    },
    { manual: true },
  );

  if (data) window.location = '/';

  const onSubmit = (requestData) => {
    executePost({ data: requestData }).catch(() => setIsInvalidValue(true));
  };

  const hasError = (key) => !!(errors.password_reset && errors.password_reset[key]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left">
        <EmailOrPhoneNumberInput
          control={control}
          register={register}
          formName="password_reset"
          backendError={isInvalidValue}
          hasError={(key) => hasError(key)}
          {...register('password_reset')}
        />
        <Button
          type="submit"
          name="Submit"
          variant="contained"
          color="secondary"
          className="text-btn-sml"
        />
        <AlertSnackbar
          open={isInvalidValue}
          message="Email address or phone number not found"
          severity="error"
          onClose={() => setIsInvalidValue(false)}
        />
      </Box>
    </form>
  );
}
