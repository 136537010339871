import disableScroll from 'disable-scroll';

$(document).ready(function () {
  $(document).ready(function () {
    $(document).on('click', '.fa-bars', function () {
      window.scrollTo(0, 0);
      $('.mobile-menu').toggle("display");
      disableScroll.on();
    });
  });

  $(document).on('click', '.fa-times', function () {
    $('.mobile-menu').hide();
    disableScroll.off();
  });

  $(document).on('click', '.mobile-menu a', function () {
    $('.mobile-menu').hide();
    disableScroll.off();
  });
});
