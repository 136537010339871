import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import List from './index';

const useStyles = makeStyles((theme) => ({
  expand: {
    color: theme.palette.black300,
  },
}));

const NestedListItem = ({ items, className, title }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClick = () => setOpen(!open);

  return (
    <div className={className}>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ExpandLess className={classes.expand} /> : <ExpandMore className={classes.expand} />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding items={items} nested />
      </Collapse>
    </div>
  );
};

export default NestedListItem;
