import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import useAxios from 'axios-hooks';

import CountrySelect from './CountrySelect';

export default function PhoneNumberInput(props) {
  const {
    formName,
    phoneNumberKey,
    onChange,
    value,
    replaceInvalidSymbols = true,
    ...inputProps
  } = props;
  const [{ data, loading, error }] = useAxios('http://ip-api.com/json');
  const [inputValue, setInputValue] = useState({ country: '', mob_number: value || '' });

  if (!loading && !inputValue.country) {
    setInputValue({ ...inputValue, country: error ? 'ZA' : data.countryCode });
  }

  return (
    <Box width="100%" display="flex">
      <CountrySelect
        name={`${formName}[country]`}
        value={inputValue.country}
        margin={inputProps.margin}
        onChange={(e) => {
          const updatedValue = { ...inputValue, country: e.target.value };
          setInputValue(updatedValue);
          return props.onChange && props.onChange(updatedValue);
        }}
      />
      <TextField
        {...inputProps}
        variant="standard"
        name={`${formName}[${phoneNumberKey}]`}
        value={inputValue.mob_number}
        style={{ width: '100%' }}
        onChange={(e) => {
          if (replaceInvalidSymbols) {
            e.target.value = e.target.value.replace(/[^\d+]/g, '');
          }
          const updatedValue = { ...inputValue, mob_number: e.target.value };
          setInputValue(updatedValue);
          return props.onChange && props.onChange(updatedValue);
        }}
      />
    </Box>
  );
}
