import React from 'react';
import AsyncTable from '../common/AsyncTable';

export default function Companies() {
  const headCells = [
    { key: 'name', label: 'Name' },
    { key: 'address', label: 'Address' },
    { key: 'phone_number', label: 'Phone Number' },
    { key: 'key_contact_id', label: 'Account contact' },
    { key: 'created_at', label: 'Account created' },
  ];

  const customLink = (row, key) => {
    return `/companies/${row.id}/edit`;
  };

  return (
    <AsyncTable
      url="companies"
      defaultOrderField="name"
      headCells={headCells}
      customLink={customLink}
    />
  );
}
