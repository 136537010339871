import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import ProjectSelect from './ProjectSelect';
import ItemsList from '../common/List';
import UserIssueModal from '../UserIssueModal';

import theme from '../../styles/Theme';

const Sidebar = ({
  listItems, projectSelectProps, formAuthenticityToken,
}) => {
  const handleProjectChange = (selectedValue) => {
    window.location.href = projectSelectProps.items.find(({ value }) => value === selectedValue).link;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ProjectSelect handleChange={handleProjectChange} {...projectSelectProps} />
        <ItemsList items={listItems}>
          <UserIssueModal csrf_token={formAuthenticityToken} mobile />
        </ItemsList>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

Sidebar.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  projectSelectProps: PropTypes.shape({}).isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
};

export default Sidebar;
