import React, { useState } from 'react';
import { parse, format } from 'date-fns';
import { DatePicker as BaseDatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePicker = (props) => {
  const {
    date = format(new Date(), 'dd/MM/yyyy'),
    dateFormat = 'dd/MM/yyyy',
    open = false,
    variant = 'dialog',
    showInput = false,
    applyDate,
    inputId,
    inputName,
    inputRef,
    className,
  } = props;
  const [selectedDate, setSelectedDate] = useState(date ? parse(date, dateFormat, new Date()) : null);
  const [isOpen, setIsOpen] = useState(open);

  const handleDateChange = (value) => {
    setSelectedDate(value);
    setIsOpen(false);
    if (applyDate) { applyDate(value); }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <BaseDatePicker
      variant={variant}
      value={selectedDate}
      onChange={handleDateChange}
      onClose={handleClose}
      open={isOpen}
      autoOk
      format="dd/MM/yyyy"
      {...(variant === 'dialog' && { okLabel: '' })}
      {...(!showInput && { TextFieldComponent: () => null })}
      InputProps={{
        id: inputId, name: inputName, inputRef, onFocus: () => setIsOpen(true), className,
      }}
      PopoverProps={{ disableRestoreFocus: true }}
    />
  );
};
export default DatePicker;
