import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import DatePicker from '../common/DatePicker';

const DateInputResponse = (props) => {
  const { answer, register } = props;
  const registerProps = register('completion_mark[options][answer]');

  return (
    <FormControl variant="standard">
      <FormControlLabel
        control={(
          <DatePicker
            inputName="completion_mark[options][answer]"
            variant="inline"
            showInput
            date={answer}
            registerProps={registerProps}
            actions={['cancel', 'accept']}
            {...props}
          />
        )}
      />
    </FormControl>
  );
};
export default DateInputResponse;
