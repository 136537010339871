import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MuiSelect from '@mui/material/Select';

import theme from '../../styles/Theme';

const useStyles = makeStyles(() => ({
  mediumSelect: {
    height: 38,
    borderRadius: 6,
    backgroundColor: theme.palette.black500,
    '& > MuiSelect-select': {
      fontSize: 15,
    },
  },
}));

const Select = (props) => {
  const {
    size = 'medium', children, className, ...selectProps
  } = props;
  const classes = useStyles();
  const selectSizeClass = size === 'medium' ? classes.mediumSelect : classes.largeSelect;
  const selectClassName = `${className} ${selectSizeClass}`;

  return (
    <MuiSelect variant="standard" className={selectClassName} {...selectProps}>
      {children}
    </MuiSelect>
  );
};

export default Select;
