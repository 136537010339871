import React from 'react';
import AsyncTable from '../common/AsyncTable';

export default function Employees() {
  const headCells = [
    { key: 'full_name', label: 'Name' },
    { key: 'id_number', label: 'ID number' },
    { key: 'company', label: 'Company' },
    { key: 'mob_number', label: 'Phone Number' },
    { key: 'speciality', label: 'Speciality' },
  ];

  const customLink = (row, key) => {
    if (Number.isInteger(row.user_id)) {
      return `/users/${row.user_id}/edit`;
    } else {
      return `/employees/${row.id}/edit`;
    }
  };

  return (
    <AsyncTable
      url="employees"
      defaultOrderField="full_name"
      headCells={headCells}
      customLink={customLink}
    />
  );
}
