import { openPopup, convertTime12to24 } from '../helpers';
import { showFlashMessage } from '../flash';

export function showTaskDetails(expandableArea) {
  var task = expandableArea.closest('.task_mobile');
  var dataAttributes = task.data();
  var data = {
    project_id: dataAttributes.projectId,
    supervisor_id: dataAttributes.supervisorId,
    competency_id: dataAttributes.competencyId,
    task_id: dataAttributes.taskId,
    task_class: dataAttributes.taskClass,
    date: dataAttributes.date
  };
  $.ajax({
    url: '/planned_employee_days/employees',
    type: 'GET',
    data: data,
    success: function (data) {
      expandableArea.find('.planned_employees').html(data);
      addGreenTickForActualTasks(expandableArea);
      var completeButton = expandableArea.find('.complete_task');
      var actualTasksStatus = task.data('completedActualTasks');
      setColorForCompleteButton(completeButton, actualTasksStatus);
      var requiredLabour = expandableArea.find('#required_labour').val();
      expandableArea.find('#required_employees').val(requiredLabour);
      if (task.find('.future_task').length) { styleFutureAssignedEmployees(expandableArea, requiredLabour); }
      updateEmployeesCounter(task, expandableArea);
      updateColorOfTaskClockIcon(task);
      var employees = expandableArea.find('.assigned_to_task_employee');
      markExtraEmployees(employees, requiredLabour);
      expandableArea.show();
    }
  });
}

function styleFutureAssignedEmployees(expandableArea, requiredLabour) {
  var employees = expandableArea.find('.assigned_to_task_employee').slice(0, requiredLabour);
  employees.addClass('future_assigned_employee');
}

function setColorForCompleteButton(completeButton, actualTasksAreCompleted) {
  if (actualTasksAreCompleted) {
    completeButton.addClass('base-green');
  } else {
    completeButton.removeClass('base-green');
  }
}

function addGreenTickForActualTasks(expandableArea) {
  var task = expandableArea.prev();
  if (($('#planner-mobile').length === 0) || task.parent().data('future')) { return; }
  expandableArea.find('.assigned_to_task_employee[data-employee-id]').each(function () {
    var tickColour = $(this).data('taskClass') === 'Task' ? 'base-green' : 'base-red';
    $(this).append('<span class="convert_to_actual_task"><i class="fa-tick ' + tickColour + '"></i></span>');
  });
}

export function updateEmployeesCounter(task, expandableArea) {
  var employeesCountWithActualTask = expandableArea.find('.formatted_tracked_time').length;
  var plannedEmployeesCount = expandableArea.find('.assigned_to_task_employee').length;
  var isActualTask = task.find('.future_task').length === 0;
  var employeesCount = isActualTask ? employeesCountWithActualTask : plannedEmployeesCount;
  var employeesCounter = task.find('.employees_counter');
  var employeesCounterText = employeesCounter.text();
  var updatedCounter = employeesCounterText.replace(/^\d+/, employeesCount);
  employeesCounter.text(updatedCounter);
  var matches = /^(\d+)\/(\d+)/.exec(updatedCounter);
  if (isActualTask && (matches[1] >= matches[2])) {
    employeesCounter.removeClass('incompleted_task').addClass('completed_task');
  }
}

export function markExtraEmployees(employees, requiredEmployeesCount) {
  var extraEmployessCount = employees.length - requiredEmployeesCount;
  if (extraEmployessCount > 0) {
    var extraEmployees = employees.slice(-extraEmployessCount);
    var employeeNames = extraEmployees.find('.colored_employee');
    employeeNames.addClass('excess_to_required');
    employeeNames.append(' (excess to required)');
  }
}

export function timeToFloat(time) {
  var parsedTime = time.split(':').map(function (s) { return parseInt(s, 10); });
  return Math.round((parsedTime[0] + parsedTime[1] / 60) * 100) / 100;
}

export function updateActivityPeriodCallback(activityPeriodSpan) {
  var attributeName = activityPeriodSpan.data('attributeName');
  var activityType = activityPeriodSpan.data('activityType');
  var text = activityPeriodSpan.text();
  var activityPeriodParams = { activity_type: activityType };
  if (text.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/)) {
    text = convertTime12to24(text);
  }
  if (attributeName === 'duration') {
    var timeAsFloat = timeToFloat(text);
    activityPeriodParams[attributeName] = timeAsFloat;
  } else {
    activityPeriodParams[attributeName] = text;
  }
  if (text !== '' && !validStringForTime(text)) {
    showFlashMessage('Invalid time', 'danger', '#tasks_popup #errors');
    return;
  }
  var context = $('#summary-step-data').length > 0 ? $('#summary-step-data') : $('#tasks_popup');
  var date = context.data('date');
  var projectId = context.data('projectId');
  var supervisorId = context.data('supervisorId');
  var employeeId = context.data('employeeId');

  var data = {
    date: date,
    employee_id: employeeId,
    project_id: projectId,
    supervisor_id: supervisorId,
    employee_activity_period: activityPeriodParams,
  };
  var updateActivitySpanCallback = function(time, flashMessage) {
    if(time) {
      activityPeriodSpan.html(time);
    } else {
      activityPeriodSpan.html("<i class='fa-stopwatch base-red'></i>");
    }
    activityPeriodSpan.show();
    if ($('#planner-mobile').length) {
      var employeeDetails = activityPeriodSpan.closest('.employee_details');
      updateClockIconColor(employeeDetails);
    } else {
      showFlashMessage(flashMessage, 'success', '#tasks_popup #errors');
    }
  };

  if(text === '') {
    deleteActivityPeriod(data, updateActivitySpanCallback);
    return;
  }
  updateActivityPeriod(data, updateActivitySpanCallback, text);
}

function deleteActivityPeriod(data, updateActivitySpanCallback) {
  changeEmployeeActivityPeriod('DELETE', data, updateActivitySpanCallback);
}

function updateActivityPeriod(data, updateActivitySpanCallback, callbackValue) {
  changeEmployeeActivityPeriod('PUT', data, updateActivitySpanCallback, callbackValue);
}

function changeEmployeeActivityPeriod(type, data, updateActivitySpanCallback, callbackValue) {
  var flashMessage = type === 'PUT' ? 'Updated' : 'Time removed';
  $.ajax({
    url: '/employee_activity_periods',
    type: type,
    data: data,
    success: function(data){
      if (data.status === 'ok') {
        updateActivitySpanCallback(callbackValue, flashMessage);
      } else {
        showFlashMessage(data.status, 'danger', '#tasks_popup #errors');
      }
    }
  });
}

export function updateClockIconColor(employeeDetails) {
  var allTimesAreSet = employeeDayWorkingHoursAreSet(employeeDetails) && actualTasksDurationsAreSet(employeeDetails);
  var clockIcon = employeeDetails.closest('.employee_mobile').find('.employees_for_day_mobile .fa-stopwatch');
  if (allTimesAreSet) {
    clockIcon.addClass('base-green');
    clockIcon.removeClass('base-red');
  } else {
    clockIcon.addClass('base-red');
    clockIcon.removeClass('base-green');
  }
}

function employeeDayWorkingHoursAreSet(employeeDetails) {
  var startTimeIsSet = employeeDetails.find('.start_time_span').data('needDefaultTime') === false;
  var finishTimeIsSet = employeeDetails.find('.finish_time_span').data('needDefaultTime') === false;
  return startTimeIsSet && finishTimeIsSet;
}

function actualTasksDurationsAreSet(employeeDetails) {
  var result = true;
  employeeDetails.find('.assigned_to_task_employee[data-task-class="Task"]').each(function () {
    var taskDuration = $(this).find('.formatted_tracked_time');
    if (taskDuration.is(':visible') === false) { result = false; }
  });
  return result;
}

export function stylePopup(el) {
  $(el).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
  $(el).closest('.ui-dialog').addClass('employees-planner-popup');
  var buttons = $(el).parents('.ui-dialog').find('.ui-button.ui-button-text-only');
  if(buttons.length > 0) {
    buttons.removeClass().addClass('nav-button');
  }
}

export function setMobilePopupWidth() {
  var windowWidth = $(window).width();
  return windowWidth > 768 ? 768 : windowWidth;
}

export function getDateFromEmployeeDay(taskCounter) {
  var innerGridCell = taskCounter.closest('.flex-grid-cell');
  var extraDays = innerGridCell.prevAll('.flex-grid-cell').length;
  var dateCounter = taskCounter.closest('.allocated').parents('.day').prevAll('.day').length + extraDays;
  var weekDay = $('.week .week-day:nth(' + dateCounter + ')');
  return weekDay.data('date');
}

export function getProjectId(taskcounter) {
  return taskcounter.closest('.allocated').parents('.week').data('projectId');
}

export function getEmployeeId(taskcounter) {
  return taskcounter.closest('.allocated').parents('.week').data('employeeId');
}

export function getSupervisorId(taskCounter) {
  return taskCounter.closest('.allocated').parents('.week').data('supervisorId');
}

export function updateTaskTrackedTimeCallback(context, parentPopup) {
  var time = $(context).text();
  var notificationSelector = getNotificationSelector(parentPopup);
  if (!validStringForTime(time)) {
    if(time !== '') {
      showFlashMessage('Invalid time', 'danger', notificationSelector);
    }
    var untrackedTimeIcon = $(context).next();
    $(context).remove();

    if (context.html() === '') {
      untrackedTimeIcon.show();
    } else {
      context.show();
    }
    return;
  }
  var duration = timeToFloat(time);
  var activityPeriodParams = { activity_type: 'working_time', duration: duration };
  var taskId = getTaskId(context, parentPopup);
  var date = parentPopup.data('date');
  var projectId = parentPopup.data('projectId');
  var supervisorId = parentPopup.data('supervisorId');
  $.ajax({
    url: '/employee_activity_periods',
    type: 'PUT',
    data: {
      date: date,
      project_id: projectId,
      supervisor_id: supervisorId,
      task_id: taskId,
      employee_activity_period: activityPeriodParams
    },
    success: function (data) {
      if (data.status === 'ok') {
       $(context).text(time);
        var coloredTask = $(context).closest('.colored_container');
        coloredTask.removeClass('base-red');
        coloredTask.addClass('base-green');
        $(context).parent().show();
        showFlashMessage('Updated', 'success', notificationSelector);
      } else {
        showFlashMessage(data.status, 'success', notificationSelector);
      }
    }
  });
}

function getNotificationSelector(parentPopup) {
  return '#' + parentPopup.attr('id') + ' #errors';
}

function getTaskId(context, parentPopup) {
  var taskId = '';
  switch (parentPopup.attr('id')) {
    case 'tasks_popup':
      taskId = $(context).closest('.employee_day_task').data('id');
      break;
    case 'summary-step-data':
      taskId = $(context).closest('.assigned_to_task_employee').data('id');
      break;
    default:
      taskId = $(context).closest('.assigned_to_task_employee').data('taskId');
  }
  return taskId;
}

export function removeTaskTrackedTimeCallback(context, parentPopup) {
  var notificationSelector = getNotificationSelector(parentPopup);
  var activityPeriodParams = { activity_type: 'working_time' };
  var taskId = getTaskId(context, parentPopup);
  var date = parentPopup.data('date');
  var projectId = parentPopup.data('projectId');
  var supervisorId = parentPopup.data('supervisorId');
  $.ajax({
    url: '/employee_activity_periods',
    type: 'DELETE',
    data: {
      date: date,
      project_id: projectId,
      supervisor_id: supervisorId,
      task_id: taskId,
      employee_activity_period: activityPeriodParams
    },
    success: function (data) {
      if (data.status === 'ok') {
        context.hide();
        context.next().show()
        var coloredTask = $(context).closest('.colored_container');
        coloredTask.removeClass('base-green');
        coloredTask.addClass('base-red');
        $(context).parent().show();
        showFlashMessage('Deleted', 'success', notificationSelector);
      } else {
        showFlashMessage(data.status, 'success', notificationSelector);
      }
    }
  });
}

export function openHover(popupHtml, elem, beforeOpenCallback) {
  removeHoverPopup();
  $(document.body).append('<div id="hover_popup" style="display:none"></div>');
  $('#hover_popup').dialog({
    position: {
      my: 'left',
      at: 'right',
      of: $(elem)
    },
    modal: false,
    autoOpen: true, resizable: false, autoSize: true,
    open: function () {
      styleHoverPopup(this);
      $('#hover_popup').html(popupHtml);
      if (beforeOpenCallback) { beforeOpenCallback(); }
    },
    hide: function () {},
    close: function () {
      removeHoverPopup();
    }
  });
}

export function checkHovering(event, element) {
  var moveInsideElement = event.relatedTarget !== element && event.toElement !== element;
  return moveInsideElement || event.relatedTarget.parentNode !== element;
}

function styleHoverPopup(el) {
  $(el).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
  $(el).siblings('.ui-dialog-titlebar').css({'display': 'none'});
  $(el).parents('.ui-dialog').css(
    {
      'border': '4px solid #E15555',
      'padding': '0',
      'border-radius': '0px'
    }
  );
}

export function removeHoverPopup() {
  if ($('#hover_popup').hasClass('ui-dialog-content')) {
    $('#hover_popup').dialog('destroy');
    $('#hover_popup').remove();
  }
}

export function validStringForTime(timeString) {
  var regexp = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return regexp.test(timeString);
}

export function validStringForFloat(floatString) {
  var regexp = /^\d+(|\.\d+)$/;
  return regexp.test(floatString);
}

export function isTaskBasedView() {
  return $('#tasks_planner_view').is(':visible') || $('.task_day_mobile').is(':visible');
}

function setRequiredEmployeesTooltip() {
  if($('.required_employees #required_employees').is(':disabled')) {
    $('.required_employees').tooltip({ content: "Can't change planned tasks for past dates" });
  }
}

export function showManagePlannedEmployeesPopup(requestParams, callback){
  $.ajax({
    url: '/planned_employee_days/manage_planned_employees_popup',
    type: 'GET',
    data: requestParams,
    success: function(content){
      var selector = '#manage_planned_employees_popup';
      var openCallback = function() {
        $(selector).data('date', requestParams['date']);
        $(selector).data('projectId', requestParams['project_id']);
        $(selector).data('supervisorId', requestParams['supervisor_id']);
        $(selector).data('competencyId', requestParams['competency_id']);
        $(selector).data('taskId', requestParams['task_id']);
        $(selector).data('taskClass', requestParams['task_class']);
        stylePopup(selector);
        if (callback) { callback(); }
      };
      var closeCallback = function() {
        $(selector).dialog('destroy');
        drawPlanner(false);
      };
      openPopup(selector, content, openCallback, closeCallback);
      setRequiredEmployeesTooltip();
    }
  });
  return false;
}

export function updateColorOfTaskClockIcon(task) {
  var assignedEmployeesCount = task.find('.assigned_to_task_employee').length;
  var employeesWithTrackedTimeCount = task.find('.assigned_to_task_employee .formatted_tracked_time:visible').length;
  if ((assignedEmployeesCount === employeesWithTrackedTimeCount) && (assignedEmployeesCount > 0)) {
    task.find('.colored_task_mobile .fa-stopwatch').removeClass('base-red').addClass('base-green');
  } else {
    task.find('.colored_task_mobile .fa-stopwatch').removeClass('base-green').addClass('base-red');
  }
}
