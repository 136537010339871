import React from 'react';
import TextField from '@mui/material/TextField';

import DynamicItem from './shared/DynamicItem';

export default function DynamicInput(props) {
  const {
    name,
    label,
    inputClass,
    values,
  } = props;
  const defaultValue = () => ({ id: `item${Math.random()}`, name: '' });
  const isValuePresent = (item) => !!item.name;

  return (
    <DynamicItem values={values} defaultValue={defaultValue} isValuePresent={isValuePresent}>
      <TextField
        key="name"
        name={name}
        label={label}
        size="small"
        inputProps={{ className: inputClass }}
      />
    </DynamicItem>
  );
}
