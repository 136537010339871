import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import MuiSelect from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
  },
  selectContainer: {
    margin: 5,
    width: 180,
    height: 38,
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor: 'white',
    },
  },
}));

const ProjectSelect = ({
  projectDropdownVisible, selectedProjectId, items, handleChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.selectContainer}>
      {projectDropdownVisible
        && (
        <Tooltip title="Change project">
          <div>
            <MuiSelect
              variant="standard"
              value={selectedProjectId}
              renderValue={(selectedValue) => items.find(({ value }) => (selectedValue === value)).name}
              className={classes.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {items.map(({ value, name }) => (
                <MenuItem
                  className={classes.listItem}
                  value={value}
                  key={value}
                  button
                  onClick={() => handleChange(value)}
                >
                  <Radio checked={value === selectedProjectId} />
                  <ListItemText id={value} primary={name} />
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
        </Tooltip>
        )}
    </div>
  );
};

ProjectSelect.propTypes = {
  selectedProjectId: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  handleChange: PropTypes.func.isRequired,
  projectDropdownVisible: PropTypes.bool,
};

ProjectSelect.defaultProps = {
  projectDropdownVisible: false,
};

export default ProjectSelect;
