"use strict";
import { parseTime } from '../src/helpers';

export function plannedExecutionGraph(options) {
  var containerId = options.containerId;
  var data = options.data;
  var notesPresent = options.notesPresent;

  ////////////////////////////////////////////////////////////
  //// Initial Setup /////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  // Style
  var colorScale = d3.scaleOrdinal().domain(["achieved_loads", "late_and_early_loads", "missed_loads", "excess_loads", "is_planned_downtime"]).range(["#73C64C", "#73C64C", "#E15555", "#29434E", "#01579B"]);

  // Dimension
  var container = d3.select("#" + containerId).classed("planned-execution-graph", true);
  var containerWidth = container.node().clientWidth;
  var containerHeight = container.node().clientHeight;
  var margin = { top: 30, right: 150, bottom: 5, left: 60 };
  var width = containerWidth - margin.left - margin.right;
  var height = containerHeight - margin.top - margin.bottom;

  // Format
  var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");
  var formatTime = d3.timeFormat("%-H:%M %p");

  // Container
  var svg = container.append("svg").attr("width", containerWidth).attr("height", containerHeight);

  var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  ////////////////////////////////////////////////////////////
  //// Layout ////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  var taskTimes = data.graph.loads.map(function (d) {
    return d.start_time;
  });
  var taskDurations = data.graph.loads.map(function (d) {
    return d.duration;
  });
  var totalDuration = d3.sum(taskDurations);
  var taskHeightScale = d3.scaleOrdinal().domain(taskTimes).range(taskDurations.map(function (d) {
    return d / totalDuration * height;
  }));

  var accumulatedTaskDurations = taskDurations.reduce(function (accumulated, duration, index) {
    accumulated.push(accumulated[index] + duration);
    return accumulated;
  }, [0]);
  accumulatedTaskDurations.pop();
  var taskYPositionScale = d3.scaleOrdinal().domain(taskTimes).range(accumulatedTaskDurations.map(function (d) {
    return d / totalDuration * height;
  }));

  var loadYPositionScale = d3.local();
  var loadHeightScale = d3.local();
  var totalLoads = d3.local();

  ////////////////////////////////////////////////////////////
  //// Chart /////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  // Heading
  svg.append("text").attr("class", "graph-heading").attr("text-anchor", "middle").attr("dy", "0.35em").attr("transform", "translate(" + (margin.left + width / 2) + "," + margin.top / 2 + ")").text(data.graph.graph_heading);

  // Task
  var task = g.selectAll(".task").data(data.graph.loads, function (d) {
    return d.start_time;
  }).enter().append("g").attr("transform", function (d) {
    return "translate(0," + taskYPositionScale(d.start_time) + ")";
  });

  // Task line
  task.append("line").attr("class", "task-line").attr("x1", -margin.left).attr("x2", width + margin.right).attr("stroke", "#1C1C1C");

  // Task time
  task.append("text").attr("class", "task-time").attr("text-anchor", "end").attr("x", -6).attr("dy", "1em").text(function (d) {
    return formatTime(parseTime(d.start_time));
  });

  // Task description
  var taskDescription = task.append("g").attr("transform", "translate(" + width + ", 0)").attr("class", "task-description-g").append("text").attr("class", "task-description").attr("text-anchor", "start").filter(function (d) {
    return !d.is_filler;
  }); // Filler tasks have no labels

  var textIndent = notesPresent ? -280 : 6

  taskDescription.append("tspan").attr("x", textIndent).attr("dy", "1em").text(function (d) {
    return d.is_planned_downtime ? d.material_name : d.material_name + " to " + d.location_name;
  });

  taskDescription.append("tspan").attr("x", function (d) {
    return d.duration > 1 ? textIndent : null;
  }).attr("dy", function (d) {
    return d.duration > 1 ? "1em" : null;
  }).text(function (d) {
    return d.is_planned_downtime ? "" : (d.duration > 1 ? "" : " ") + ("(" + d.total_loads + "/" + d.planned_loads + ")");
  });

  if (notesPresent) {
    task.append("text").attr("x", 510).attr("dy", "1em").attr("y", 40).attr("font-size", 16).attr("width", "420px").text(function (d) {
      return d.note;
    }).call(wrap);
  }

  // Load
  var load = task.append("g").attr("class", "load-g");
  var nothingToDraw = true;
  var loadRectWidth = notesPresent ? 499 : width;
  // IsPlannedDowntime Rect
  load.filter(function (d) {
    if (d.is_planned_downtime) {
      nothingToDraw = false;
      return true;
    }
    return false
  }).append("rect").attr("class", "load-rect").attr("x", 0).attr("y", 0).attr("width", loadRectWidth).attr("height", function (d) {
    return taskHeightScale(d.start_time);
  }).attr("fill", colorScale("is_planned_downtime"));

  // Other rects
  load.filter(function (d) {
    if (!d.is_planned_downtime && !d.is_filler) {
      nothingToDraw = false;
      return true;
    }
    return false;
  }).each(function (d) {
    var taskHeight = taskHeightScale(d.start_time);
    var loadTypes = d.excess_loads > 0 ? ["achieved_loads", "late_and_early_loads", "excess_loads"] : ["achieved_loads", "late_and_early_loads", "missed_loads"];
    var loads = loadTypes.map(function (loadType) {
      return d[loadType];
    });
    var totalLoads = d3.sum(loads);
    var currentLoadHeightScale = d3.scaleOrdinal().domain(loadTypes).range(loads.map(function (d) {
      if (totalLoads === 0) return taskHeight;

      return d / totalLoads * taskHeight;
    }));
    var accumulatedLoads = loads.reduce(function (accumulated, load, index) {
      accumulated.push(accumulated[index] + load);
      return accumulated;
    }, [0]);
    accumulatedLoads.pop();
    var currentLoadYPositionScale = d3.scaleOrdinal().domain(loadTypes).range(accumulatedLoads.map(function (d) {
      if (totalLoads === 0) return 0;

      return d / totalLoads * taskHeight;
    }));
    loadHeightScale.set(this, currentLoadHeightScale);
    loadYPositionScale.set(this, currentLoadYPositionScale);
  }).selectAll(".load-rect").data(function () {
    return loadYPositionScale.get(this).domain();
  }).enter().append("rect").attr("class", "load-rect").attr("x", 0).attr("y", function (d) {
    return loadYPositionScale.get(this)(d);
  }).attr("width", loadRectWidth).attr("height", function (d) {
    return loadHeightScale.get(this)(d);
  }).attr("fill", function (d) {
    return colorScale(d);
  });

  if (nothingToDraw) {
    $('#' + containerId).css({ 'color': 'black', 'text-align': 'center', 'font-size': '28px' });
    container.text('No Plan');
  }


  ////////////////////////////////////////////////////////////
  //// Resize ////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  function resize() {
    var containerWidth = container.node().clientWidth;
    var containerHeight = container.node().clientHeight;

    if (containerWidth !== 0 || containerHeight !== 0) {
      var width = containerWidth - margin.left - margin.right;
      var height = containerHeight - margin.top - margin.bottom;

      svg.attr("width", containerWidth).attr("height", containerHeight);

      // Layout
      taskHeightScale.range(taskDurations.map(function (d) {
        return d / totalDuration * height;
      }));
      taskYPositionScale.range(accumulatedTaskDurations.map(function (d) {
        return d / totalDuration * height;
      }));

      // Chart
      // Heading
      svg.select(".graph-heading").attr("transform", "translate(" + (margin.left + width / 2) + "," + margin.top / 2 + ")");

      // Task
      task.attr("transform", function (d) {
        return "translate(0," + taskYPositionScale(d.start_time) + ")";
      });

      // Task line
      task.select(".task-line").attr("x2", width + margin.right);

      // Task description
      task.select(".task-description-g").attr("transform", "translate(" + width + ", 0)");

      // IsPlannedDowntime Rect
      load.filter(function (d) {
        return d.is_planned_downtime;
      }).select(".load-rect").attr("width", loadRectWidth).attr("height", function (d) {
        return taskHeightScale(d.start_time);
      });

      // Other rects
      load.filter(function (d) {
        return !d.is_planned_downtime && !d.is_filler;
      }).each(function (d) {
        var taskHeight = taskHeightScale(d.start_time);
        var loadTypes = d.excess_loads > 0 ? ["achieved_loads", "excess_loads"] : ["achieved_loads", "late_and_early_loads", "missed_loads"];
        var loads = loadTypes.map(function (loadType) {
          return d[loadType];
        });
        var totalLoads = d3.sum(loads);
        var currentLoadHeightScale = d3.scaleOrdinal().domain(loadTypes).range(loads.map(function (d) {
          return d / totalLoads * taskHeight;
        }));
        var accumulatedLoads = loads.reduce(function (accumulated, load, index) {
          accumulated.push(accumulated[index] + load);
          return accumulated;
        }, [0]);
        accumulatedLoads.pop();
        var currentLoadYPositionScale = d3.scaleOrdinal().domain(loadTypes).range(accumulatedLoads.map(function (d) {
          return d / totalLoads * taskHeight;
        }));
        loadHeightScale    = currentLoadHeightScale;
        loadYPositionScale = currentLoadYPositionScale;
      }).selectAll(".load-rect").attr("y", function (d) {
        return loadYPositionScale(d);
      }).attr("width", width).attr("height", function (d) {
        return loadHeightScale(d);
      });
    }
  }

  var throttledResize = throttle(resize, 500);
  window.addEventListener("resize", throttledResize);

  ////////////////////////////////////////////////////////////
  //// Utilities /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function later() {
      previous = options.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    return function () {
      var now = Date.now();
      if (!previous && options.leading === false) previous = now;
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  }

  function wrap(text) {
    text.each(function() {
      var text = d3.select(this);
      var words = text.text().split(/\s+/).reverse();
      var lineHeight = 40;
      var width = parseFloat(text.attr('width'));
      var y = parseFloat(text.attr('y'));
      var x = text.attr('x');
      var anchor = text.attr('text-anchor');

      var tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('text-anchor', anchor);
      var lineNumber = 0;
      var line = [];
      var word = words.pop();

      while (word) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          lineNumber += 1;
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', x).attr('y', y + lineNumber * lineHeight).attr('anchor', anchor).text(word);
        }
        word = words.pop();
      }
    });
  }
}
window.plannedExecutionGraph = plannedExecutionGraph;
