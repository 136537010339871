import React from 'react';
import AsyncTable from '../common/AsyncTable';

export default function Plants() {
  const selectedDate = sessionStorage.getItem('selectedDate');
  const date = !selectedDate ? new Date() : new Date(selectedDate);

  const headCells = [
    { key: 'name', label: 'Plant Name' },
    {
      key: 'project_id', label: 'Project Name', customContent: true, customClickHandler: true,
    },
  ];

  const customClicksHandler = ({ projectId }, key) => {
    if (key === 'project_id') {
      window.location.href = `/projects/${projectId}/plan?date=${date}`;
    }
  };

  const customLink = (row, key) => {
    if (key === 'project_id') {
      return `/projects/${row.project_id}/plan?date=${date}`;
    }
    return `/plants/${row.id}`;
  };

  const customCellContentProvider = ({
    project_id: projectId, project_name: projectName,
  }, key) => {
    if (key === 'project_id') {
      const pathname = `/projects/${projectId}/plan?date=${date}`;
      return <a href={pathname}>{projectName}</a>;
    }

    return null;
  };

  return (
    <AsyncTable
      url="plants"
      defaultOrderField="name"
      headCells={headCells}
      customClicksHandler={customClicksHandler}
      customCellContentProvider={customCellContentProvider}
      customLink={customLink}
    />
  );
}
