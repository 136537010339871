import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import useAxios from 'axios-hooks';
import { ErrorMessage } from '@hookform/error-message';
import Box from '@mui/material/Box';

import Button from './Button';
import EmailOrPhoneNumberInput from './EmailOrPhoneNumberInput';
import { renderError } from '../helpers/errors';

export default function LogInForm(props) {
  const { csrf_token: csrfToken } = props;
  const [shouldRememberMe, setShouldRememberMe] = useState(true);
  const {
    register, handleSubmit, control, formState: { errors }, setError, clearErrors,
  } = useForm({ mode: 'onChange' });
  const [{ data }, executePost] = useAxios(
    {
      url: '/login',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    },
    { manual: true },
  );

  if (data) window.location = data.url;

  const onSubmit = (requestData) => {
    executePost({ data: requestData }).catch((error) => {
      const errorType = error.response.data.error === 'not_activated' ? 'not_activated' : 'credentials';
      setError(`session[${errorType}]`, { type: 'server' });
    });
  };

  const handleRememberMeCheckboxChange = (event) => {
    setShouldRememberMe(event.target.checked);
  };

  const hasError = (key) => !!(errors.session && errors.session[key]);

  const handleInputKeyUp = () => {
    clearErrors(['session[credentials]', 'session[not_activated]']);
  };

  useEffect(() => {
    window.addEventListener('keyup', handleInputKeyUp);
    return () => window.removeEventListener('keyup', handleInputKeyUp);
  }, []);

  const passwordProps = register('session[password]', { required: true });
  const rememberProps = register('session[remember_me]');
  const { ref: rememberRef } = rememberProps;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" justifyContent="left" alignItems="left">
        <EmailOrPhoneNumberInput
          register={register}
          control={control}
          formName="session"
          backendError={hasError('credentials')}
          hasError={(key) => hasError(key)}
        />
        <TextField
          name="session[password]"
          placeholder="Password"
          size="small"
          type="password"
          inputProps={passwordProps}
          error={hasError('credentials') || hasError('password')}
          helperText={hasError('password') && 'Password is required'}
        />
        <FormControlLabel
          control={(
            <Checkbox
              name="session[remember_me]"
              checked={shouldRememberMe}
              size="small"
              onChange={handleRememberMeCheckboxChange}
              inputRef={rememberRef}
            />
          )}
          label="Remember me on this device"
        />
        <ErrorMessage
          errors={errors}
          name="session[not_activated]"
          message="Your account is not activated, please check your email for a confirmation
            or email support@linknbuild.com to resolve this"
          render={({ message }) => renderError(message)}
        />
        <ErrorMessage
          errors={errors}
          name="session[credentials]"
          message="Invalid login credentials"
          render={({ message }) => renderError(message)}
        />
        <Button
          type="submit"
          name="Log in"
          variant="contained"
          color="secondary"
          className="text-btn-sml"
          style={{ marginLeft: '25%' }}
        />
        <Link className="red" href="/password_resets/new" underline="none" marginLeft="25%">Forgot password?</Link>
      </Box>
    </form>
  );
}
