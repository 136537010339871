import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRight from '@mui/icons-material/ChevronRight';

import NestedListItem from './NestedListItem';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 'auto',
    color: theme.palette.black300,
  },
}));

const ListItemLink = (props) => <ListItem button component="a" {...props} />;

const CustomListItem = ({ item, className, onClick }) => {
  const classes = useStyles();

  return (item.items
    ? <NestedListItem className={className} {...item} />
    : (
      <ListItemLink
        className={`${className} ${item.classNames ? item.classNames : ''}`}
        data-method={item['data-method']}
        id={item.id}
        href={item.link}
        onClick={onClick}
      >
        <ListItemText primary={item.title} />
        <ChevronRight className={classes.icon} fontSize="small" />
      </ListItemLink>
    ));
};

export default CustomListItem;
