import React from 'react';
import AsyncTable from '../common/AsyncTable';

export default function Projects() {
  const headCells = [
    { key: 'name', label: 'Project' },
    { key: 'status', label: 'Status' },
    { key: 'client', label: 'Client' },
    { key: 'full_name', label: 'Project Manager' },
    { key: 'default_bonus', label: 'Default bonus' },
    { key: 'description', label: 'Description' },
    { key: 'address', label: 'Address' },
  ];

  const customLink = (row, key) => {
    return `/projects/${row.id}/edit`;
  };

  return (
    <AsyncTable
      url="projects"
      defaultOrderField="name"
      headCells={headCells}
      customLink={customLink}
    />
  );
}
