import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MuiToolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'unset',
    padding: theme.spacing(2),
  },
  button: {
    margin: 0,
  },
}));

const Toolbar = ({ searchVisible, handleToggleVisibility }) => {
  const classes = useStyles();
  return (
    <MuiToolbar className={classes.container}>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={() => handleToggleVisibility(!searchVisible)}
      >
        {searchVisible ? 'Hide' : 'Search'}
      </Button>
    </MuiToolbar>
  );
};

export default Toolbar;
