import React from 'react';
import BaseTable from '../common/BaseTable';
import { handleKpiTimeTargetClick, handleKpiTargetClick } from '../../src/kpis';

export default function KpiTargets({ rows, projectId }) {
  const headCells = [
    { key: 'name', label: 'Name' },
    { key: 'value', label: 'Value' },
  ];

  const handleRowClick = (kpi) => {
    if (kpi.time_target) {
      handleKpiTimeTargetClick(projectId, kpi.kpi_target_id, kpi.value);
    } else {
      handleKpiTargetClick(projectId, kpi.kpi_target_id);
    }
  };

  return (
    <BaseTable
      rows={rows}
      headCells={headCells}
      handleRowClick={handleRowClick}
    />
  );
}
