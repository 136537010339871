import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import AsyncTable from '../common/AsyncTable';
import { openSubjectAccessorsPopup } from '../../src/permission_rules';

export default function Users({ isSuper }) {
  const headCells = [
    { key: 'full_name', label: 'Name' },
    { key: 'email', label: 'Email' },
    { key: 'mob_number', label: 'Mobile Number' },
    { key: 'office_number', label: 'Office number' },
    { key: 'company_name', label: 'Company' },
    {
      key: 'project_access', label: 'Project access', customContent: true, customClickHandler: true,
    },
    { key: 'user_setup', label: 'User Setup' },
  ];

  if (isSuper) {
    headCells.push({ key: 'last_login_time', label: 'Last login time', customContent: true });
  }

  const customClicksHandler = ({ id }, key) => {
    if (key === 'project_access') {
      openSubjectAccessorsPopup(id);
    }
  };

  const customLink = (row, key) => {
    if (key === 'project_access') {
      return '/users';
    }
    return `/users/${row.id}/edit`;
  };

  const customCellContentProvider = (row, key) => {
    if (key === 'project_access') {
      return (
        <a href="#">{row[key]}</a>
      );
    }

    if (key === 'last_login_time') {
      return (
        <Tooltip title={row.data_time}>
          <div>
            {row[key]}
          </div>
        </Tooltip>
      );
    }

    return null;
  };

  return (
    <AsyncTable
      url="users"
      defaultOrderField="full_name"
      headCells={headCells}
      customClicksHandler={customClicksHandler}
      customCellContentProvider={customCellContentProvider}
      className="employee"
      customLink={customLink}
    />
  );
}
