import React, { useState } from 'react';
import Switch from '@mui/material/Switch';

const ToggleResponse = (props) => {
  const { answer } = props;
  const [state, setState] = useState(answer);
  const handleChange = (event) => {
    setState(event.target.checked);
  };

  return (
    <Switch
      color="secondary"
      name="completion_mark[options][answer]"
      checked={state}
      onChange={handleChange}
    />
  );
};
export default ToggleResponse;
